import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-stream',
  templateUrl: './stream.component.html',
  styleUrls: ['./stream.component.scss']
})
export class StreamComponent {
  route = this.router.url;
  stream_id: any = this.active_route.snapshot.paramMap.get('stream-id');

  constructor(private router: Router, private active_route: ActivatedRoute) { }
}
