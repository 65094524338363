import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MenusService {
  base_url = environment.urls.base_url;

  private menus = new BehaviorSubject(localStorage.getItem('vocoloopMenus'));
  currentMenu = this.menus.asObservable();

  updateMenus(menu: any) {
    this.menus.next(menu);
  }
  
  constructor(private http: HttpClient) {}

  getFooterMenus() {
    return this.http.get(this.base_url + "livo/v1/menus/");
  }

}
