import { Component, OnInit } from '@angular/core';
import { DataStoreService } from 'src/app/apis/data-storage/data-store.service';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss']
})
export class NotificationsComponent implements OnInit {
  has_unread_notifications: boolean = false;
  notifications: any = [];
  img_path = '';
  constructor(private data_store_api: DataStoreService) { }

  ngOnInit(): void {
    this.data_store_api.currentBaseUrl.subscribe((url: string) => this.img_path = url + "assets/img/icons/");
  }

}
