<!-- Login (default) -->
<div *ngIf="view === 'login'">
    <div class="login-section" *ngIf="is_user_validated">
        <div *ngIf="is_signup" class="is-signup">
            <h3>Welcome Back</h3>
            <p>Login on Vocoloop and get started</p>
        </div>
        <div *ngIf="!is_signup" class="is-listening">
            <h3>This is private streaming...!!!</h3>
            <p>Please login to listen live audio</p>

        </div>

        <form [formGroup]="loginForm">
            <div class="form-group"
                [ngClass]="{'input-error':loginForm.controls.username.invalid && (loginForm.controls.username.dirty || loginForm.controls.username.touched || is_form_invalid)}">
                <label for="username">E-mail</label>
                <input type="text" class="form-control" formControlName="username" placeholder="E-mail">
                <p class="text-error" *ngIf="loginForm.controls.username.errors?.required"> email is required</p>
                <p class="text-error" *ngIf="loginForm.controls.username.errors?.email"> </p>
            </div>
            <div class="form-group"
                [ngClass]="{'input-error':loginForm.controls.password.invalid && (loginForm.controls.password.dirty || loginForm.controls.password.touched || is_form_invalid)}">
                <label for="password">Password</label>
                <input type="password" class="form-control" formControlName="password" placeholder="Password">
                <p class="text-error" *ngIf="loginForm.controls.password.errors?.required"> Password is required</p>
            </div>
            <div class="action-container" [ngClass]="{'submitted': is_submitted}">
                <div class="button-group d-block">
                    <button (click)="loginFormSubmit(loginForm)"
                        class="w-100 btn-fill button text-uppercase">Login</button>
                    <p *ngIf="is_signup" class="text-center">Didn't remember password? <br> <a class="link"
                            (click)="forgotPassword()">Forgot password</a></p>
                    <p *ngIf="is_signup" class="text-center">Don't have an account? <br> <a class="link"
                            routerLink="/signup/register">Register here</a></p>
                </div>
                <span *ngIf="is_submitted" class="spinner-border loader"></span>
            </div>
        </form>
    </div>
    <div class="user-validate" *ngIf="!is_user_validated">
        <h3>Confirm your E-mail</h3>
        <p>Check email and verify your e-mail address</p>

        <form [formGroup]="validateUserForm">
            <div class="form-group"
                [ngClass]="{'input-error':validateUserForm.controls.code.invalid && (validateUserForm.controls.code.dirty || validateUserForm.controls.code.touched || is_form_invalid) || verification_code_err !==''}">
                <label for="code">Verification Code</label>
                <input type="text" class="form-control" formControlName="code" placeholder="code">
                <span *ngIf="!resend_link_status">
                    {{ ((resend_link < 10) ? '00:0' :'00:') + resend_link }} 
                </span>
                        <div *ngIf="(validateUserForm.controls.code.errors?.required || verification_code_err !=='')">
                            <p class="text-error"
                                *ngIf="(validateUserForm.controls.code.errors?.required); else responseErr">verification
                                code is required</p>

                        </div>
                        <ng-template #responseErr>
                            <p class="text-error">
                                {{ verification_code_err }}
                            </p>

                        </ng-template>

            </div>
            <div class="action-container" [ngClass]="{'submitted': is_submitted}">

                <div class="button-group">
                    <button (click)="verifyUser(validateUserForm)"
                        class="btn-fill button text-uppercase">Verify</button>
                </div>
                <p class="text-center" *ngIf="!is_submitted">
                    <a class="link" [ngClass]="{isDisabled: !resend_link_status}"
                        (click)="resendVerificationCode(user_data.email, user_data.token)">Resend verification code</a>
                </p>

                <span *ngIf="is_submitted" class="spinner-border loader"></span>
            </div>
        </form>
    </div>
</div>

<!-- forgot password -->

<div *ngIf="view === 'forgot'" class="forgot">
    <h3>Confirm your E-mail</h3>
    <p>Check email and verify your e-mail address</p>

    <form [formGroup]="forgotForm">
        <div class="form-group"
            [ngClass]="{'input-error':forgotForm.controls.email.invalid && (forgotForm.controls.email.dirty || forgotForm.controls.email.touched || is_form_invalid)}">
            <label for="email">E-mail</label>
            <input type="text" class="form-control" formControlName="email" placeholder="E-mail">
        </div>
        <div class="action-container" [ngClass]="{'submitted': is_submitted}">

            <div class="button-group">
                <button (click)="resetPassword(forgotForm)" [disabled]="(forgotForm.status === 'INVALID')"
                    class="btn-fill button text-uppercase">Forgot password</button>
            </div>
            <span *ngIf="is_submitted" class="spinner-border loader"></span>
        </div>
    </form>
</div>

<div *ngIf="view ==='reset'">
    <h3>Confirm your E-mail</h3>
    <p>Check email and verify your e-mail address</p>

    <form [formGroup]="resetPassForm">
        <div class="form-group"
            [ngClass]="{'input-error':resetPassForm.controls.code.invalid && (resetPassForm.controls.code.dirty || resetPassForm.controls.code.touched || is_form_invalid)}">
            <label for="email">Verification code</label>
            <input type="text" class="form-control" formControlName="code" placeholder="verification code">
        </div>
        <div class="form-group"
            [ngClass]="{'input-error':resetPassForm.controls.new_password.invalid && (resetPassForm.controls.new_password.dirty || resetPassForm.controls.new_password.touched || is_form_invalid)}">
            <label for="new_password">New password</label>
            <input type="text" class="form-control" formControlName="new_password" placeholder="New password">
        </div>
        <div class="action-container" [ngClass]="{'submitted': is_submitted}">

            <div class="button-group">
                <button (click)="updatePassword(resetPassForm)" [disabled]="(resetPassForm.status === 'INVALID')"
                    class="btn-fill button text-uppercase">Reset password</button>
            </div>
            <span *ngIf="is_submitted" class="spinner-border loader"></span>
        </div>
    </form>
</div>