<div class="responsive">
    <div class="mt-3 mb-5">
        <p>Language</p>
        <div class="lang-options">
            <div (click)="updateLang('en')" [ngClass]="{active: (active_lang === 'en')}" for="english">English</div>
            <div (click)="updateLang('hi')" [ngClass]="{active: (active_lang === 'hi')}" for="hindi">Hindi</div>
            <div (click)="updateLang('ml')" [ngClass]="{active: (active_lang === 'ml')}" for="malayalam">Malayalam</div>
            <!-- <div [ngClass]="{active: (active_lang === 'ta')}" for="tamil">Tamil</div> -->
            <div (click)="updateLang('te')" [ngClass]="{active: (active_lang === 'te')}" for="telugu">Telugu</div>
            <!-- <div [ngClass]="{active: (active_lang === 'ar')}" for="arabic">Arabic</div> -->
        </div>
    </div>
    <!-- <div class="mt-3">
        <p>Themes</p>
        <div class="theme-options">
            <div for="light">Light</div>
            <div for="dark">Dark</div>
            <div for="aqua">Aqua</div>
            <div for="color">Color</div>
        </div>
    </div> -->
    <div class="mt-3">
        <p>Timezone</p>
        <div class="theme-options">
            <select class="form-control-sm thin-scrollbar" (change)="updateTimezone($event)">
            <option *ngFor="let timezone of timezones" [value]="timezone" [selected]="timezone === user_timezone">{{ timezone }}</option>
        </select>
        </div>
    </div>
    <div class="mt-3">
        <p>Account</p>
        <div class="theme-options">
            Do you want to change password ? <a class="btn link" (click)="resetPassword(user_details.user_email)">Reset my password</a>
        </div>
    </div>
</div>