<div class="container">
    <div class="align-items-center row text-center text-sm-left">
        <div class="img-section col-12 col-sm-6">
            <div class="container">
                <div class="image-responsive">
                    <img src="{{img_path}}/UI_Mob.png" alt="">
                </div>
            </div>
        </div>
        <div class="col-12 col-sm-6 description-section">
            <div class="container mb-5 mt-5">
                <h3 [innerHTML]="'home.sectionAppStore.title' | translate">Never stop listening</h3>
                <p [innerHTML]="'home.sectionAppStore.desc' | translate">Vocoloop will shortly available on <br> ios, android. Request early access.</p>
            </div>

            <div class="links-section mb-5">
                <ul class="list-inline">
                    <li class="list-inline-item" *ngFor="let app of app_list">
                        <a (click)="show_download_form(app.os)" [class]="app.class_list" target="_blank">
                            <img [src]="app.icon" alt="">
                        </a>
                    </li>
                </ul>
            </div>

        </div>
    </div>
</div>