import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PageService {
  base_url = environment.urls.base_url;
  auth_token = '';

  constructor(private http: HttpClient) {
    this.auth_token = JSON.stringify(localStorage.getItem('sessionUserToken'));
  }

  getSinglePage(request: any) {
    return this.http.get(this.base_url + "wp/v2/pages/?slug=" + request);
  }

  getMobileApp(request: Object) {
    console.log(request);
    
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: JSON.parse(this.auth_token)
      })
    };
    return this.http.post(this.base_url + "livo/v1/wishlist", request);
  }
}
