<!-- <img src="{{img_path}}notification.svg" alt=""> -->
<div class="notification-btn cursor-pointer" [autoClose]="'outside'" popoverClass="notification-popup" [ngbPopover]="popContent" placement="bottom-right" #notifier="ngbPopover" [popoverTitle]="popTitle">
    <img *ngIf="has_unread_notifications" src="{{img_path}}notification_new.svg" alt="">
    <img *ngIf="!has_unread_notifications" src="{{img_path}}notification.svg" alt="">
</div>

<ng-template #popTitle>
    <span class="unselectable custom-popover-title">{{'notifications.text' | translate }}</span>
</ng-template>
<ng-template class="custom-popover-content" #popContent>
    <ul *ngIf="notifications?.length > 0">
        <li>Hi...</li>
    </ul>
    <p class="unselectable no-notifications" *ngIf="notifications?.length === 0">{{'notifications.status' | translate}}
    </p>
</ng-template>