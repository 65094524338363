import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthenticationGuard } from './authentication.guard';
import { DashboardComponent } from './dashboard/dashboard.component';
import { HomeComponent } from './home/home.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { SignupComponent } from './signup/signup.component';
import { SingleComponent } from './single/single.component';
import { NewStreamingComponent } from './stream/hosting/new-streaming/new-streaming.component';
import { StreamComponent } from './stream/stream.component';

const routes: Routes = [
  { path: '', component: HomeComponent, data: {title: 'Home'} },
  { path: 'p/:slug', component: SingleComponent, data: {title: ':slug'} },
  { path: 'signup', component: SignupComponent, data: {title: 'Signup'}, children: [
    { path: 'login', component: SignupComponent, data: {title: 'Login', view: 'login'} },
    { path: 'register', component: SignupComponent, data: {title: 'Register', view: 'register'} },
  ] },
  { path: 'logout', component: HomeComponent, data: {title: 'Home'} },
  { path: 'hosting/new', component: NewStreamingComponent, data: {title: 'New stream'}, canActivate: [AuthenticationGuard]  },
  { path: 'hosting/view/:stream-id', component: NewStreamingComponent, canActivate: [AuthenticationGuard] },
  { path: 'hosting/:stream-id', component: StreamComponent, data: {title: 'hosting page'}, canActivate: [AuthenticationGuard] },
  { path: 'hosting/completed', component: StreamComponent, data: {title: 'hosting completed page'}, canActivate: [AuthenticationGuard] },
  { path: 'live/:stream-id', component: StreamComponent, data: {title: 'Listerner page'}},
  { path: 'dashboard', component: DashboardComponent, data: {title: 'Dashboard'}, canActivate: [AuthenticationGuard] },
  { path: 'explore', redirectTo: '' },
  { path: '**', component: PageNotFoundComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
