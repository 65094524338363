<section>
    <div class="content" *ngIf="stage===1">
        <h3>This is FREE beta version</h3>
        <div class="modal-body">
            <!-- <h5>subtitle Begin</h5> -->
            <p>Our goal is to get external feedback in order to improve our systems and make them scalable and error
                free.
            </p>
            <p>While we have safeguards in place, the system may occasionally drop live connections and voice may break.
                Kindly try live events for smaller audiences.</p>
        </div>
    </div>
    <div *ngIf="stage===2">
        <h3>What we collect</h3>
        <div class="modal-body">
            <p>All live events are stores in AWS S3 and Conversations may be reviewed by our developers to improve our
                systems.</p>
            <p>Please don't share any sensitive information in your conversations and live events. Don't miss use the
                system.</p>
        </div>
    </div>
    <div *ngIf="stage===3">
        <h3>We'd love your feedback</h3>
        <div class="modal-body">
            <p>Share your feedback in our contact page or email vocoloop@gmail.com</p>
        </div>
    </div>


    <div class="modal-footer tour-modal-footer">
        <div class="action-left">
            <button (click)="toggleContent(0)">Skip</button>
        </div>

        <div class="action-right">

            <button *ngIf="stage > 1" (click)="toggleContent(stage -1)">back</button>
            <button *ngIf="stage !== max_stages" (click)="toggleContent(stage +1)">next</button>
            <button *ngIf="stage === max_stages" (click)="toggleContent(0)">Finish</button>
        </div>
    </div>
</section>