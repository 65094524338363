import { Component, ElementRef, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { StreamService } from 'src/app/apis/stream.service';

declare const window: { clipboardData: any; };

@Component({
  templateUrl: './private-listening-token.component.html',
  styleUrls: ['./private-listening-token.component.scss']
})
export class PrivateListeningTokenComponent implements OnInit {
  @Input() details: any = [];
  otpForm: FormGroup = this.formBuilder.group({
    otp1: ['', [Validators.required, Validators.minLength(1), Validators.maxLength(1)]],
    otp2: ['', [Validators.required, Validators.minLength(1), Validators.maxLength(1)]],
    otp3: ['', [Validators.required, Validators.minLength(1), Validators.maxLength(1)]],
    otp4: ['', [Validators.required, Validators.minLength(1), Validators.maxLength(1)]],
  });
  is_loading: boolean = false;
  otp_status = {
    error: false, message: ""
  }
  constructor(
    private formBuilder: FormBuilder,
    private modalRef: NgbActiveModal,
    private stream_api: StreamService
  ) { }

  updateVal(event: ClipboardEvent) {
    event.preventDefault();
    let clipboardData = event.clipboardData || window.clipboardData;
    clipboardData = clipboardData.getData("text/plain");
    let otp_array = clipboardData.toString(10).replace(/\D/g, '').split('').map(Number);
    if (otp_array.length) {
      this.update_otp_fordata(this.otpForm, otp_array);
    }
  }

  focusInput(event: KeyboardEvent, next: HTMLInputElement, prev: HTMLInputElement) {
    event.preventDefault();
    const key = event.key.toLowerCase();
    const number_arr = key.toString().replace(/\D/g, '').split('').map(Number);
    if (number_arr.length > 0) {
      let otp = Object.values(this.otpForm.value).toString().replace(/\D/g, '').split('').map(Number);
      if (otp.length) {
        this.update_otp_fordata(this.otpForm, otp);
        next.focus();
      }
    }
    if (key === 'backspace') {
      prev.focus();
    }
    let is_loaded = false;
    if (this.otpForm.valid && !is_loaded) {
      is_loaded = true;
      let otp = Number(Object.values(this.otpForm.value).join(''));
      this.is_loading = true;
      this.verifyOtp(otp);
    }
  }

  update_otp_fordata(fb: FormGroup, otp: any) {
    fb.patchValue({
      otp1: otp[0],
      otp2: otp[1],
      otp3: otp[2],
      otp4: otp[3]
    });
  }

  verifyOtp(otp: Number) {
    let option = {
      post_id: this.details.post_id, passcode: otp
    };
    this.stream_api.privateListeningValidate(option).subscribe((result: any) => {
      this.is_loading = true;
      if (result?.status) {
        this.is_loading = false;
        this.otp_status.error = false;

        this.modalRef.dismiss({
          results: {
            status: 'success',
            message: "Token has been verified"
          }
        });
      } else {
        this.otp_status.error = true;
        this.otp_status.message = result?.message;
        this.update_otp_fordata(this.otpForm, ['', '', '', '']);
      }
    }, error => console.log(error), () => {
      this.is_loading = false;
    });

  }
  ngOnInit(): void {
  }

}
