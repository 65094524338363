import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SignupService {
  base_url = environment.urls.base_url;
  constructor(private http: HttpClient) {
    // if (location.hostname === 'localhost') {
    //   this.base_url = 'http://localhost/livo-wp/wp-json/';
    // }
  }

  userLoginCheck(options: Object) {
    return this.http.post(this.base_url + "jwt-login/v1/auth", options);

  }

  userVerifyCheck(email: string, token: string) {
    /************************************
     * email: string = EMAIL_ID
     * token: string = auth_token
     */
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: token
      })
    };
    return this.http.get(this.base_url + "livo/v1/voco_email_varified?user_email=" + email, httpOptions);
  }

  userVerifyValidate(options: Object) {
    /************************************
     * options: object = { user_email: EMAIL_ID, code: EMAIL_CODE }
     */
    return this.http.post(this.base_url + "livo/v1/voco_email_validate", options);

  }

  resendUserVerifyCode(email: string = '', token: string = '') {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: token
      })
    };
    return this.http.get(this.base_url + "livo/v1/voco_resend_email?user_email=" + email, httpOptions);
  }

  userRegistration(options: Object) {
    return this.http.post(this.base_url + "jwt-login/v1/users", options);
  }
  
  userLogin(token: string) {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: token
      })
    };
    return this.http.get(this.base_url + "jwt-login/v1/autologin", httpOptions);
  }

  forgot(options: Object) {
    return this.http.post(this.base_url + "jwt-login/v1/user/reset_password", options);
    
  }
  resetPassword(options: Object) {
    return this.http.put(this.base_url + "jwt-login/v1/user/reset_password", options);
  }

  getUser(token: string) {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: token
      })
    };
    return this.http.get(this.base_url + 'livo/v1/user', httpOptions);

  }

  updateUser(options: Object, token: string) {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: token
      })
    };

    return this.http.post(this.base_url + 'livo/v1/user_update', options, httpOptions);
  }

  updateAvatar(options: any, token: string) {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: token
      })
    };
    return this.http.post(this.base_url + 'livo/v1/user_avatar', options, httpOptions);
  }

  updateUserPassword(email: Object, token: string) {
    // let url = this.base_url.replace("wp-json/", "");
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: token
      })
    };
    return this.http.post(this.base_url + 'jwt-login/v1/user/reset_password', {email: email}, httpOptions);
  }

  userLogout(token: string) {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: token
      })
    };
    return this.http.post(this.base_url + "jwt-login/v1/auth/revoke/", { JWT: token }, httpOptions);
  }
}
