<div class="row chat-panel">
    <div class="col-sm-12">
        <div class="chat-container">
            <div class="chat-header-container">
                <div class="d-flex align-items-center">
                    <div class="lhr"></div>
                    <p class="comments-count mr-2">{{ 'stream.chat.title' | translate }}</p>
                    <div class="rhr"></div>
                    <span class="d-flex align-items-center">
                        <img class="mr-2" src="{{img_path}}chat.svg" alt="">
                        {{ chat_count }}
                    </span>
                </div>
            </div>
            <div [class.empty-chat-container]="chat_count === 0" class="chat-panel-outer">
                <div #scrollMe [class]="(chat_count === 0) ? '' : 'thin-scrollbar'">
                    <div class="chat-panel-note-container">
                        <div class="chat-note" [innerHTML]="chat_note"></div>
                    </div>

                    <div *ngIf="chat_count === 0; else chatList">
                        <!-- <div class="empty-chat">{{ 'stream.chat.noChatText' | translate }}</div> -->
                    </div>
                    <ng-template #chatList>
                        <div *ngFor="let chat of chat_data" class="chat-panel-container"
                            [ngClass]="{self: user_details?.ID === chat?.user_id}">
                            <ng-container *ngIf="user_details?.ID === chat?.user_id; else othersChat">
                                <div #newItem class="d-flex align-items-top">
                                    <div class="timeline">{{ chat.updated_at | date:'hh:mm a' }}</div>
                                    <div class="content-container text-right">
                                        <div class="messeger" [title]="chat.user_name" [innerHTML]="chat.user_name">
                                        </div>
                                        <div class="message" [innerHTML]="chat.message"></div>
                                    </div>
                                    <div class="img-responsive">
                                        <img [src]="chat.user_profile" alt="">
                                    </div>
                                </div>
                            </ng-container>

                            <ng-template #othersChat>
                                <div class="d-flex align-items-top">
                                    <div class="img-responsive">
                                        <img [src]="chat.user_profile" alt="">
                                    </div>
                                    <div class="content-container">
                                        <div class="messeger" [title]="chat.user_name" [innerHTML]="chat.user_name">
                                        </div>
                                        <div class="message" [innerHTML]="chat.message"></div>
                                    </div>
                                    <div class="timeline">{{ chat.updated_at | date: 'hh:mm a' }}</div>
                                </div>
                            </ng-template>
                        </div>
                    </ng-template>

                </div>
            </div>
            <div class="chat-input-container">
                <form [formGroup]="chattingForm" (ngSubmit)="sendMsg()" class="d-flex align-middle">
                    <p class="emoji-btn" [class.disabled]="!is_loggedin" (click)="showEmojiPicker = !showEmojiPicker">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                            <path
                                d="M12 0a12 12 0 1 0 0 24 12 12 0 0 0 0-24m0 22a10 10 0 1 1 0-20 10 10 0 0 1 0 20M8 7a2 2 0 1 0 0 4 2 2 0 0 0 0-4m8 0a2 2 0 1 0 0 4 2 2 0 0 0 0-4m-.8 8c-.7 1.2-1.8 2-3.3 2-1.5 0-2.7-.8-3.4-2H15m3-2H6a6 6 0 1 0 12 0">
                            </path>
                        </svg>
                    </p>
                    <input [class.disabled]="!is_loggedin" type="text" formControlName="message"
                        [placeholder]="'stream.chat.inputPlaceholder' | translate">
                    <!-- <input type="submit" class="chat-btn" value="Send"> -->
                    <button [disabled]="!is_loggedin || !chattingForm.valid" class="chat-btn"><img
                            src="{{img_path}}send_message.svg" alt=""></button>
                </form>
                <!-- emoji set options are: google, twitter, facebook, apple -->
                <emoji-mart class="emoji-mart" *ngIf="showEmojiPicker" set="twitter" (emojiSelect)="addEmoji($event)"
                    title="Pick your emoji…" [style]="{ width: '100%' }">
                </emoji-mart>
            </div>
            <p *ngIf="!is_loggedin" class="pt-3 pb-3 mb-0"><span class="text-danger note-text">*</span> {{
                'stream.chat.loginNote' | translate }}</p>

        </div>
    </div>
</div>