<div class="stream-url mb-4">
    <ng-template #privateStreamContent>
        <span class="vl-tooltip-content" *ngIf="streamurl_data?.is_private">
            {{ 'stream.copyUrl.tooltipPvt' | translate }}
            <a href="https://vocoloop.com/app/faq/" target="_blank" [innerHTML]="'learnMoreBtn' | translate">Learn
                more</a>
        </span>
        <span class="vl-tooltip-content" *ngIf="!streamurl_data?.is_private">
            {{ 'stream.copyUrl.tooltipPublic' | translate }}
            <a href="https://vocoloop.com/app/faq/" target="_blank" [innerHTML]="'learnMoreBtn' | translate">Learn
                more</a>
        </span>
    </ng-template>
    <div class="inline-flex-justify-between">
        <p>
            <span class="mr-1" [innerHTML]="'stream.copyUrl.label' | translate" tooltipClass="vl-help-tooltip"
                [ngbTooltip]="privateStreamContent" [autoClose]="false" placement="top-left" triggers="manual"
                #sPrivate="ngbTooltip"></span>
            <svg (click)="sPrivate.toggle()" (blur)="sPrivate.close()" class="tooltip-help-icon"
                xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#2b80d3">
                <path d="M0 0h24v24H0z" fill="none" />
                <path
                    d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 17h-2v-2h2v2zm2.07-7.75l-.9.92C13.45 12.9 13 13.5 13 15h-2v-.5c0-1.1.45-2.1 1.17-2.83l1.24-1.26c.37-.36.59-.86.59-1.41 0-1.1-.9-2-2-2s-2 .9-2 2H8c0-2.21 1.79-4 4-4s4 1.79 4 4c0 .88-.36 1.68-.93 2.25z" />
            </svg>
        </p>
        <p *ngIf="streamurl_data?.is_private">
            <span [innerHTML]="streamurl_data?.passcode_text"></span>
            <span [innerHTML]="streamurl_data?.passcode"></span>
        </p>
    </div>
    <a #copyLink [routerLink]="''" class="sr-only">copy url</a>
    <div class="d-flex">
        <input type="text" #copyLinkInput [formControl]="copy_url" placeholder="http://bit.ly/qwd765g410"
            readonly="true">
        <button [cdkCopyToClipboard]="copyLinkInput.value" (click)="linkCopied(copy_tooltip, copyPopover)"
            (mouseover)="CopyLink(copy_tooltip, copyPopover)" (mouseleave)="copyPopover.close()"
            [ngbPopover]="copy_url_tooltip" #copyPopover="ngbPopover" [ngbTooltip]="copied_url_tooltip"
            tooltipClass="copy-url-btn-tooltip" #copy_tooltip="ngbTooltip" triggers="manual" [autoClose]="false">
            <img src="{{img_path}}copy.svg" alt="">
        </button>
    </div>
</div>