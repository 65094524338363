import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private redirectUrl: string = location.origin;
  constructor() { }
  getToken() {
    return !!localStorage.getItem('sessionUserToken');
  }

  setRedirectUrl(url: string): void {
    this.redirectUrl = url;
  }

  getRedirectUrl(): string {
    return this.redirectUrl;
  }
}
