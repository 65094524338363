import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { DataStoreService } from 'src/app/apis/data-storage/data-store.service';

@Component({
  selector: 'app-tabs',
  templateUrl: './tabs.component.html',
  styleUrls: ['./tabs.component.scss']
})
export class TabsComponent implements OnInit {
  user_details: any;
  user_base_details: any;
  activated_tab = (this.route.snapshot.fragment) ? this.route.snapshot.fragment : 'profile'; 
                  // activated_tab values are: profile / scheduled / completed / settings
  img_path = '';
  constructor(private data_store_api: DataStoreService, private route: ActivatedRoute, private router: Router) { }

  filterByKeys(full_data: any, filter_keys: any) {
    return Object.keys(full_data).filter(keys => filter_keys.includes(keys)).reduce((item: any, item_key: string) => {
      item[item_key] = full_data[item_key];
      return item;
    }, {});
  }
  navigateTo(url: string) {
    this.router.navigateByUrl(url);

  }
  ngOnInit(): void {
    this.data_store_api.currentBaseUrl.subscribe((url: string) => this.img_path = url + "assets/img/icons/dashboard");
    this.router.events.subscribe((event: any) => {
      if (event instanceof NavigationEnd) {
        if (event.url.includes("dashboard")) {
          this.activated_tab = this.route.snapshot.fragment
        }
      }
    });
    this.data_store_api.currentUserDetails.subscribe((details: any) => {
      this.user_details = JSON.parse(details);
      this.user_base_details = this.filterByKeys(this.user_details, ["display_name", "user_email"]);
    });
  }

}
