<div class="new-stream-form">
    <form [formGroup]="new_streaming" (ngSubmit)="streaming_submit()">
        <div class="form-group">
            <label class="sr-only" for="event_name" [innerHTML]="'stream.form.event.label' | translate">Event
                name</label>
            <input type="text" formControlName="title" class="form-control" id="event_name"
                [placeholder]="'stream.form.event.placeholder' | translate">
        </div>
        <div class="form-group">
            <label class="sr-only" for="description"
                [innerHTML]="'stream.form.desc.label' | translate">Description</label>
            <textarea formControlName="content" rows="7" class="form-control" id="description"
                [placeholder]="'stream.form.desc.placeholder' | translate"></textarea>
        </div>
        <div class="form-group" *ngIf="stream_categories?.length > 0">
            <label class="sr-only" for="category" [innerHTML]="'stream.form.event.label' | translate">Category</label>
            <div class="select-box">
                <select formControlName="categoryid" class="form-control">
                    <option value="" [innerHTML]="'stream.form.category.emptyOpt' | translate">Select a category
                    </option>
                    <option *ngFor="let cat of stream_categories" [value]="cat.term_id">{{ cat.name }}</option>
                </select>
            </div>
        </div>

        <div class="form-group toggle-opt">
            <ng-template #is_scheduled><span class="vl-tooltip-content"
                    [innerHTML]="'stream.form.isSchedule.tooltip' | translate">User must be logged in to listen
                    audio. <a href="https://vocoloop.com/app/faq/" target="_blank"
                        [innerHTML]="'learnMoreBtn' | translate">Lean more</a></span>
            </ng-template>
            <div tooltipClass="vl-help-tooltip" [ngbTooltip]="is_scheduled" [autoClose]="false" triggers="manual"
                [placement]="tooltip_pos" #schedule="ngbTooltip">
                <label class="form-check-label text-capitalize mr-1" for="stream_schedule"
                    [innerHTML]="'stream.form.isSchedule.label' | translate">Stream</label>
                <svg (click)="schedule.toggle()" (blur)="schedule.close()" class="tooltip-help-icon"
                    xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#2b80d3">
                    <path d="M0 0h24v24H0z" fill="none" />
                    <path
                        d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 17h-2v-2h2v2zm2.07-7.75l-.9.92C13.45 12.9 13 13.5 13 15h-2v-.5c0-1.1.45-2.1 1.17-2.83l1.24-1.26c.37-.36.59-.86.59-1.41 0-1.1-.9-2-2-2s-2 .9-2 2H8c0-2.21 1.79-4 4-4s4 1.79 4 4c0 .88-.36 1.68-.93 2.25z" />
                </svg>
            </div>
            <div class="toggle-inner">
                <div class="toggle-item">
                    <input type="checkbox" class="checkbox" FormControl="scheduled" id="stream_schedule"
                        (change)="updateStreamTiming(!scheduled.value)" [checked]="scheduled.value === true">
                    <div class="knobs">
                        <span [innerHTML]="'stream.form.isSchedule.values.yes' | translate">Now</span>
                        <span [innerHTML]="'stream.form.isSchedule.values.no' | translate">Schedule</span>
                    </div>
                    <!-- <div class="knobs">
                    </div> -->
                    <div class="layer"></div>
                </div>
            </div>
        </div>

        <div class="form-group date-picker" [class.active]="is_schedule">
            <label class="sr-only" for="schedule_date">Schedule date</label>
            <input [owlDateTime]="dt1" formControlName="livetime" placeholder="Date Time" class="form-control">
            <span class="datepicker-trigger" [owlDateTimeTrigger]="dt1">
                <img src="{{img_path}}datepicker.svg" alt="">
            </span>
            <owl-date-time #dt1></owl-date-time>
        </div>
        <div class="form-group toggle-opt">
            <ng-template #privateStreamContent><span class="vl-tooltip-content"
                    [innerHTML]="'stream.form.isPvt.tooltip' | translate">User must be logged in to listen
                    audio. <a href="https://vocoloop.com/app/faq/" target="_blank"
                        [innerHTML]="'learnMoreBtn' | translate">Lean more</a></span>
            </ng-template>
            <div tooltipClass="vl-help-tooltip" [ngbTooltip]="privateStreamContent" [autoClose]="false"
                [placement]="tooltip_pos" triggers="manual" #sPrivate="ngbTooltip">
                <label class="form-check-label text-capitalize mr-1" for="private_stream"
                    [innerHTML]="'stream.form.isPvt.label' | translate">
                    Private streaming
                </label>
                <svg (click)="sPrivate.toggle()" (blur)="sPrivate.close()" class="tooltip-help-icon"
                    xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#2b80d3">
                    <path d="M0 0h24v24H0z" fill="none" />
                    <path
                        d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 17h-2v-2h2v2zm2.07-7.75l-.9.92C13.45 12.9 13 13.5 13 15h-2v-.5c0-1.1.45-2.1 1.17-2.83l1.24-1.26c.37-.36.59-.86.59-1.41 0-1.1-.9-2-2-2s-2 .9-2 2H8c0-2.21 1.79-4 4-4s4 1.79 4 4c0 .88-.36 1.68-.93 2.25z" />
                </svg>
            </div>
            <div class="toggle-inner">
                <div class="toggle-item">
                    <input type="checkbox" formControlName="visibility" class="form-check-input" class="checkbox"
                        id="private_stream">

                    <div class="knobs">
                        <span [innerHTML]="'stream.form.isPvt.values.yes' | translate">Now</span>
                        <span [innerHTML]="'stream.form.isPvt.values.no' | translate">Schedule</span>
                    </div>
                    <div class="layer"></div>
                </div>
            </div>
        </div>
        <div class="form-group toggle-opt">
            <ng-template #hideChatContent>
                <span class="vl-tooltip-content" [innerHTML]="'stream.form.chat.tooltip' | translate">
                    Disable public chat feature from event.
                    <a href="https://vocoloop.com/app/faq/" target="_blank"
                        [innerHTML]="'learnMoreBtn' | translate">Lean more</a>
                </span>
            </ng-template>
            <div tooltipClass="vl-help-tooltip" [ngbTooltip]="hideChatContent" [autoClose]="false" triggers="manual"
                [placement]="tooltip_pos" #dMsg="ngbTooltip">
                <label class="form-check-label text-capitalize mr-1" for="hide_comment"
                    [innerHTML]="'stream.form.chat.label' | translate">
                    Disable messaging
                </label>
                <svg (click)="dMsg.toggle()" (blur)="dMsg.close()" class="tooltip-help-icon"
                    xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#2b80d3">
                    <path d="M0 0h24v24H0z" fill="none" />
                    <path
                        d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 17h-2v-2h2v2zm2.07-7.75l-.9.92C13.45 12.9 13 13.5 13 15h-2v-.5c0-1.1.45-2.1 1.17-2.83l1.24-1.26c.37-.36.59-.86.59-1.41 0-1.1-.9-2-2-2s-2 .9-2 2H8c0-2.21 1.79-4 4-4s4 1.79 4 4c0 .88-.36 1.68-.93 2.25z" />
                </svg>
            </div>
            <div class="toggle-inner">
                <div class="toggle-item">
                    <input type="checkbox" formControlName="hide_chat" class="checkbox form-check-input"
                        id="hide_comment">
                    <div class="knobs">
                        <span [innerHTML]="'stream.form.chat.values.yes' | translate">Now</span>
                        <span [innerHTML]="'stream.form.chat.values.no' | translate">Schedule</span>
                    </div>
                    <!-- <div class="knobs">
                        </div> -->
                    <div class="layer"></div>
                </div>
            </div>
        </div>
        <button type="submit" [disabled]="(is_submitted) || (new_streaming.status === 'INVALID')"
            class="btn form-submit"><span>{{ btn_text }}</span> <span
                [ngClass]="{'spinner-border': is_submitted}"></span></button>

    </form>

</div>