import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DataStoreService {
  constructor(private router: ActivatedRoute) { }
  private user_token = new BehaviorSubject(localStorage.getItem('sessionUserToken'));  // cards outline cols
  private user_detail = new BehaviorSubject(localStorage.getItem('user_details'));  // cards outline cols
  private time_zones = new BehaviorSubject([]);  // cards outline cols
  private stream_detail = new BehaviorSubject({});  // cards outline cols
  // private base_url = new BehaviorSubject((location.hostname !== 'localhost') ? location.origin + '/' + location.pathname.split('/').filter((item: any) => item = item !=='')[0]+ '/' : location.origin + '/');  // cards outline cols
  private base_url = new BehaviorSubject(location.origin + '/');  // cards outline cols
  private is_loader = new BehaviorSubject(true);  // if loader is loading return true
  private is_reconnecting = new BehaviorSubject(false);  // if loader is loading return true
  private login_view = new BehaviorSubject('login'); // login | forgot | reset
  private categories = new BehaviorSubject(localStorage.getItem('vocoStreamCategories'));
  private stream_id = new BehaviorSubject(localStorage.getItem('vocoStreamId'));
  private content_lang = new BehaviorSubject(localStorage.getItem('vlContentLang'));

  currentUserToken = this.user_token.asObservable();
  currentUserDetails = this.user_detail.asObservable();
  currentTimezones = this.time_zones.asObservable();
  currentHostingDetails = this.stream_detail.asObservable();
  currentBaseUrl = this.base_url.asObservable();
  currentLoaderStatus = this.is_loader.asObservable();
  currentReconnectingStatus = this.is_reconnecting.asObservable();
  currentLoginView = this.login_view.asObservable();
  currentStreamingCategories = this.categories.asObservable();
  getLiveStreamingId = this.stream_id.asObservable();
  currentContentLang = this.content_lang.asObservable();

  updateUserToken(token: string) {
    this.user_token.next(token);
  }

  updateUserDetails(user_data: string) {
    this.user_detail.next(user_data);
  }

  updateTimeZones(zones: []) {
    this.time_zones.next(zones);
  }

  updateHostDetails(details: any) {
    this.time_zones.next(details);
  }

  updateImgLocation(path: string) {
    this.base_url.next(path);
  }

  toggleLoader(status: boolean) {
    this.is_loader.next(status);
  }

  toggleReconnecting(status: boolean) {
    this.is_reconnecting.next(status);
  }

  toggleLoginView(view: string) {
    this.login_view.next(view);
  }

  updateStreamingCategories(categories: any) {
    this.categories.next(categories);
  }

  updateLiveStreamingId(stream_id: string) {
    this.stream_id.next(stream_id);
  }

  updateContentLang(lang: string) {
    this.content_lang.next(lang);
  }

  setCookie(key: string, value: string, duration: number) {

  }
  getCookie(key: string = '') {
    let result = '';
    if (key !== '') {
      let result = document.cookie.split(";");
      let cookies: any = {};
      result = result.map((item: any) => item.trim().split('=')).filter(([key, value]) => cookies[key] = value);
      result = cookies[key];
      return result;
    }
    return result;
  }
  removeCookie(key: string) {
    return this.setCookie(key, '', 0);
  }
}
