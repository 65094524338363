<div class="col-sm-12 responsive">
    <div class="row">
        <div
            class="position-unset col-12 col-sm-8 col-md-5 col-lg-6 ml-auto mr-auto ml-md-0 mr-md-0 mb-4 mb-md-0 pl-md-0">
            <div class="edit-profile-container">
                <div class="edit-profile-panel">
                    <div class="form-group mb-4">
                        <label class="sr-only" for="profile">User Profile</label>
                        <input type="file" class="sr-only" id="profile" (change)="showMe($event)">
                        <label for="profile" class="btn image-container m-auto d-block position-relative">
                            <div class="rounded-circle img-preview"
                                [style.background-image]="'url(' + user_details.user_avatar + ')'"></div>
                            <img class="position-absolute rounded-circle edit-icon" src="{{img_path}}Edit_black.svg"
                                alt="">
                        </label>
                        <button (click)="updateAvatar(avatar.value)" class="btn btn-outline m-auto d-block"
                            [ngClass]="{'sr-only': !profile_update}">Update profile</button>
                    </div>
                    <form [formGroup]="profile_details" (ngSubmit)="updateProfile(profile_details)">
                        <div class="form-group mb-4">
                            <label for="display_name">
                                <span class="unselectable text-label text-capitalize">Display name</span>
                            </label>
                            <input type="text" class="form-control"
                                [ngClass]="{'has-error': (profile_details.controls.display_name.status !== 'VALID' && is_submitted)}"
                                id="display_name" formControlName="display_name">
                        </div>
                        <div class="user-email-field">
                            <p for="user_email" class="unselectable" [innerHTML]="user_details.user_email"></p>
                            <label class="sr-only" for="user_email">Email</label>
                        </div>
                        <div class="form-group mb-4" tooltipClass="vl-help-tooltip"
                        ngbTooltip="Share a little biographical information to fill out your profile. This may be shown publicly." [autoClose]="false" triggers="manual"
                        #bio_ttip="ngbTooltip">
                            <label for="user_bio">
                                <span class="unselectable text-label text-capitalize">About yourself
                                    <svg (click)="bio_ttip.toggle()" (blur)="bio_ttip.close()"
                                        class="tooltip-help-icon" xmlns="http://www.w3.org/2000/svg" height="24px"
                                        viewBox="0 0 24 24" width="24px" fill="#2b80d3">
                                        <path d="M0 0h24v24H0z" fill="none" />
                                        <path
                                            d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 17h-2v-2h2v2zm2.07-7.75l-.9.92C13.45 12.9 13 13.5 13 15h-2v-.5c0-1.1.45-2.1 1.17-2.83l1.24-1.26c.37-.36.59-.86.59-1.41 0-1.1-.9-2-2-2s-2 .9-2 2H8c0-2.21 1.79-4 4-4s4 1.79 4 4c0 .88-.36 1.68-.93 2.25z" />
                                    </svg>
                                </span>
                            </label>
                            <textarea id="user_bio" class="form-control rounded" rows="4"
                                [ngClass]="{'has-error': (profile_details.controls.user_bio.status !== 'VALID' && is_submitted)}"
                                formControlName="user_bio"></textarea>
                        </div>
                        <div class="form-group checkbox-switch p-0 container position-relative">
                            <ng-template #make_private_tooltip>
                                <span class="vl-tooltip-content">make my profile invisible from public.
                                    <a href="https://vocoloop.com/app/faq/" target="_blank">Lean more</a></span>
                            </ng-template>

                            <div class="form-check-container" tooltipClass="vl-help-tooltip"
                                [ngbTooltip]="make_private_tooltip" [autoClose]="false" triggers="manual"
                                #mpvt_ttip="ngbTooltip">
                                <input type="checkbox" (dblclick)="mpvt_ttip.toggle()" class="form-check-input"
                                    id="profile_visibility" formControlName="user_profile_visibility">
                                <label class="form-check-label" for="profile_visibility">
                                    <span class="unselectable text-label">Make my account private
                                        <svg (click)="mpvt_ttip.toggle()" (blur)="mpvt_ttip.close()"
                                            class="tooltip-help-icon" xmlns="http://www.w3.org/2000/svg" height="24px"
                                            viewBox="0 0 24 24" width="24px" fill="#2b80d3">
                                            <path d="M0 0h24v24H0z" fill="none" />
                                            <path
                                                d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 17h-2v-2h2v2zm2.07-7.75l-.9.92C13.45 12.9 13 13.5 13 15h-2v-.5c0-1.1.45-2.1 1.17-2.83l1.24-1.26c.37-.36.59-.86.59-1.41 0-1.1-.9-2-2-2s-2 .9-2 2H8c0-2.21 1.79-4 4-4s4 1.79 4 4c0 .88-.36 1.68-.93 2.25z" />
                                        </svg>
                                    </span>
                                    <span class="switch"></span>
                                </label>
                            </div>
                        </div>
                        <div class="btn-container d-flex justify-content-end mt-4">
                            <input type="submit" class="btn bg-default" value="Save">
                        </div>
                    </form>
                </div>
            </div>
        </div>
        <div class="col-12 col-sm-8 ml-auto mr-auto ml-md-0 mr-md-0 col-md-5 col-lg-4">
            <div class="card-panels">
                <div class="panel-card">
                    <p class="content unselectable text-capitalize">Total stream - {{ user_details.user_total_streams || '00' }}</p>
                    <div class="icon"><img src="{{img_path}}count_stream.svg" alt=""></div>
                </div>
                <div class="panel-card">
                    <p class="content unselectable text-capitalize">Following - {{ user_details.user_total_following || '00' }}</p>
                    <div class="icon"><img src="{{img_path}}count_following.svg" alt=""></div>
                </div>
                <div class="panel-card">
                    <p class="content unselectable text-capitalize">Follower - {{ user_details.user_total_followers || '00' }}</p>
                    <div class="icon"><img src="{{img_path}}count_follower.svg" alt=""></div>
                </div>
            </div>
        </div>
    </div>
</div>