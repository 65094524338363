import { Component, AfterViewInit, AfterContentChecked, OnInit, HostListener } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal, NgbTooltip, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { StreamService } from 'src/app/apis/stream.service';
import { ConfirmWindowComponent } from 'src/app/popups/confirm-window/confirm-window.component';
import { ToastService } from 'src/app/apis/toast.service';
import { DataStoreService } from 'src/app/apis/data-storage/data-store.service';
import { ChatService } from 'src/app/apis/firebase/chat.service';
import { TranslateService } from '@ngx-translate/core';
import { TourComponent } from 'src/app/popups/tour/tour.component';

declare let Webcaster: any;
declare let stream: any;
declare let track: any;

export function isMobile() {
  return (
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile/i.test(navigator.userAgent) &&
    'ontouchstart' in window
  );
}

@Component({
  selector: 'app-hosting',
  templateUrl: './hosting.component.html',
  styleUrls: ['./hosting.component.scss']
})

export class HostingComponent implements AfterViewInit, OnInit, AfterContentChecked {
  @HostListener('document:visibilitychange', ['$event']) function() {
    if (this.is_mobile) {
      this.pauseStream();
      if (document.hidden) {
        this.data_store_api.toggleLoader(true);
      } else {
        setTimeout(() => {
          this.data_store_api.toggleLoader(false);
        }, 4000);
      }
    }


  }
  stream_id: string = this.activated_route.snapshot.paramMap.get('stream-id') || '';
  stream_url = 'live/' + this.stream_id;
  is_chat_hide: boolean = true;
  is_streaming: boolean = false;
  is_loading = true;
  stream_status = "waiting";
  stream_data: any;
  toast_opt = { classname: 'toast-success', header: '' };
  img_path = '';
  chat_popup: boolean = false;
  readMore: boolean = true;
  elapsed_time = "00:00";
  elapsed_count: number = -1;
  clap_count: number = 0;
  timerIntval: any;
  listeners_count: number = 0;
  sync_data: any;
  is_muted: boolean = false;
  stream_action_btn_tooltip_content = '';
  closeResult = '';
  selmicDevice: string = "No microphone found";
  availMicDevices: any = [];
  selspeakerDevice: string = "No microphone found";
  streamcontext_status: string = "inactive";
  settings_active_tab: string = "audio"; // audio | status
  is_mobile = isMobile();
  reloaded = false;
  has_permision = true;
  stream_counter: number = 60;
  constructor(
    private stream_api: StreamService,
    private activated_route: ActivatedRoute,
    private route: Router,
    private modal: NgbModal,
    private toast: ToastService,
    private data_store_api: DataStoreService,
    private fire_api: ChatService,
    private translate: TranslateService

  ) { }

  open(content: any) {
    let modalOpt = { ariaLabelledBy: 'modal-basic-title', centered: true, size: "xl", windowClass: 'modal-default' };
    this.modal.open(content, modalOpt).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
  showTooltip(is_streaming: boolean, tooltip: NgbTooltip) {
    if (is_streaming) {
      tooltip.open();
    } else {
      this.translate.get(['stream.hostTooltips.micOn']).subscribe((trans: any) => {
        this.stream_action_btn_tooltip_content = trans['stream.hostTooltips.micOn'];
        tooltip.open();
      });
    }
  }

  closeTooltip(is_streaming: boolean, is_muted: boolean, tooltip: NgbTooltip) {
    if (is_streaming) {
      setTimeout(() => {
        tooltip.close();
      }, 100);
    } else if (is_muted) {
      setTimeout(() => {
        tooltip.close();
      }, 100);
    }
  }
  toggleStream(status: boolean) {
    this.toast_opt.classname = 'toast-success';
    this.toast_opt.header = '';
    if (status) {
      this.toast.show("Microphone turned off", this.toast_opt);
    } else {
      this.toast.show("Microphone turned on", this.toast_opt);
    }
    this.is_muted = !status;
    return this.pauseStream();
  }
  async startStream() {
    this.translate.get(['stream.hostTooltips.micOff']).subscribe((trans: any) => {
      this.stream_action_btn_tooltip_content = trans['stream.hostTooltips.micOff'];
    });
    this.stream_status = 'loading';
    this.sync_data = setInterval(() => {

      if (this.stream_status !== 'completed') {
        this.stream_api.syncStreamData(this.stream_id).subscribe((data: any) => {
          this.stream_status = data?.live?.is_live ? 'live' : this.stream_status;
          this.listeners_count = data?.listeners?.current;
          if (this.streamcontext_status !== 'running' && this.streamcontext_status !== 'interrupted' && this.streamcontext_status === 'suspended') {
            alert(`Status is: ` + this.streamcontext_status);
          }
          if (this.streamcontext_status === 'suspended') {
            clearInterval(this.sync_data);
            clearInterval(this.timerIntval);
            console.log(`Status is: ` + this.streamcontext_status);

            let modalRef = this.modal.open(ConfirmWindowComponent, {
              ariaLabelledBy: 'modal-basic-title',
              size: 'sm',
              keyboard: false,
              centered: true,
              windowClass: 'confirm-box',
              backdropClass: 'confirm-box-backdrop'
            });
            modalRef.componentInstance.is_form = false;
            modalRef.componentInstance.message = `Are you sure want to restart this hosting?`;
            modalRef.componentInstance.confirm = "Restart";
            modalRef.componentInstance.cancel = "Cancel";
            modalRef.result.then((result) => {
              console.log(result);
              if (result === "cancelled") {
              }
            }, (result) => {
              if (result === "confirmed") {
                this.stream_status = "reconnecting";
                Webcaster.start();
                this.data_store_api.toggleReconnecting(true);
              }
            });
          }

        }, (err: any) => console.log(err), () => {
          this.is_streaming = this.stream_status === 'live';
          this.is_loading = !this.is_streaming;
          this.fire_api.updateStreamStatus(this.stream_status, this.stream_id);
        });
      } else {
        clearInterval(this.sync_data);
      }

    }, 10000);
    this.timerIntval = setInterval(() => {
      if (this.is_streaming) {
        if (this.stream_status !== 'completed') {
          if (this.stream_status === 'live') {
            this.elapsed_count = this.elapsed_count + 1;
            this.elapsed_time = this.sec_to_time_convert(this.elapsed_count);
          }

        } else {
          clearInterval(this.timerIntval);
        }
      }
    }, 1000);
  }

  pauseStream() {
    this.is_muted = !this.is_muted;
    Webcaster.toggleRecording();
  }

  stopStream() {
    let modalRef = this.modal.open(ConfirmWindowComponent, {
      ariaLabelledBy: 'modal-basic-title',
      size: 'sm',
      keyboard: false,
      centered: true,
      windowClass: 'confirm-box',
      backdropClass: 'confirm-box-backdrop'
    });
    modalRef.componentInstance.is_form = false;
    modalRef.componentInstance.message = "Are you sure want to stop this hosting?";
    modalRef.componentInstance.confirm = "Stop";
    modalRef.componentInstance.cancel = "Cancel";
    modalRef.result.then(() => {
    }, (result) => {
      this.data_store_api.toggleLoader(true);
      this.stopStreaming();
    });
  }

  stopStreaming() {
    this.stream_api.handleStream("end-live?stream_id=" + this.stream_id).subscribe(async (data: any) => {
      this.toast_opt.header = data?.status;
      if (data?.status === "success") {
        Webcaster.stop();
        track.stop();
        this.is_streaming = false;
        localStorage.removeItem('vocoStreamId');
        this.data_store_api.updateLiveStreamingId('');
        clearInterval(this.timerIntval);
        this.data_store_api.toggleLoader(false);
      } else if (data?.status === "error") {
        console.log(data);
        this.toast_opt.classname = "toast-error";
      } else {
        console.log(data);
      }
      window.scroll(0, 0);
    }, (err: any) => console.log(console.log(err)), () => {
      this.stream_status = 'completed';
      this.fire_api.updateStreamStatus(this.stream_status, this.stream_id);
    });
  }

  counter(i: number) {
    return new Array(i);
  }

  sec_to_time_convert(time: number) {
    let hours: any = Math.floor(time / 3600);
    let minutes: any = Math.floor((time - (hours * 3600)) / 60);
    let seconds: any = time - (hours * 3600) - (minutes * 60);

    if (hours < 10) { hours = "0" + hours; }
    if (minutes < 10) { minutes = "0" + minutes; }
    if (seconds < 10) { seconds = "0" + seconds; }

    return minutes + ":" + seconds;
  }

  get_stream_copy_url() {
    const options = {
      url: this.stream_url,
      is_private: this.stream_data?.visibility === 'private',
      title: 'Streaming URL :',
      passcode_text: (this.stream_data?.passcode_text !== '') ? this.stream_data?.passcode_text : 'Pass code',
      passcode: this.stream_data?.post_password
    }
    if (options.passcode_text === undefined) {
      options.passcode_text = 'Pass code: ';
    }
    return options;
  }

  get_stream_details(stream_id: string) {
    this.stream_api.displayCreatedStream(stream_id).subscribe((res: any) => {
      if (res.status === "success") {
        this.data_store_api.currentUserDetails.subscribe((user_details: any) => {
          if (JSON.parse(user_details).ID !== res.author_details.ID) {
            this.route.navigate(['/']);

          }
        });
        this.stream_data = res;
      } else {
        this.data_store_api.updateLiveStreamingId(res.status);
      }
    }, (err: any) => console.log(err), () => {

      if (this.stream_data) {

        this.is_chat_hide = (this.stream_data?.hide_chat === 1);
        if (this.stream_data?.stream_status.toLowerCase() !== ('completed')) {
          this.stream_init(stream_id);
        } else {
          this.stream_status = this.stream_data?.stream_status;
          this.fire_api.updateStreamStatus(this.stream_status, this.stream_id);
          this.data_store_api.toggleLoader(false);
        }
      } else {
        let modalRef = this.modal.open(ConfirmWindowComponent, {
          ariaLabelledBy: 'modal-basic-title',
          size: 'sm',
          keyboard: false,
          centered: true,
          windowClass: 'confirm-box',
          backdropClass: 'confirm-box-backdrop',
          backdrop: 'static'
        });
        modalRef.componentInstance.is_form = false;
        modalRef.componentInstance.message = "Event is not live.";
        modalRef.componentInstance.cancel = "Back to home";
        modalRef.componentInstance.confirm = "Create new";
        modalRef.result.then(() => {
          this.route.navigate(['/']);
        }, (reason) => {
          this.route.navigate(['/hosting/new']);
        });
      }
    });
  }
  stream_init(stream_id: string) {
    this.fire_api.updateStreamStatus(this.stream_status, stream_id);
    let start_counting = setInterval(() => {
      if (this.stream_counter > 0) {
        this.stream_counter = this.stream_counter - 1;
      } else {
        clearInterval(start_counting);
      }
    }, 1000);
    let result: any;
    this.stream_api.handleStream("start-live?stream_id=" + stream_id).subscribe((detail: any) => {
      result = detail;
      if (this.stream_status !== "reconnecting") {
        this.stream_status = "connecting";
      }
    }, (err: any) => {
      if (err.code === 401) {
        this.has_permision = false;
        this.toast_opt.classname = 'toast-error';
        this.toast_opt.header = err?.status;
        this.toast.show(err?.message, this.toast_opt);
        this.route.navigate(['/']);
      } else {
        this.stream_status = "reconnecting";
        console.log(err);
        this.data_store_api.toggleLoader(false);
        this.data_store_api.toggleReconnecting(false);
      }
    }, () => {
      this.data_store_api.toggleLoader(false);
      this.data_store_api.toggleReconnecting(false);
      if (result.status === 'success') {
        Webcaster.metadata = { title: 'hello', artist: '' };
        Webcaster.uri = atob(result.route);
        setTimeout(() => {
          Webcaster.start();
          Webcaster.toggleRecording();
          this.stream_status = "connected";
          this.fire_api.updateStreamStatus(this.stream_status, stream_id);
          this.startStream();

        }, 30000);
      } else {
        if (result.code === 401) {
          this.has_permision = false;
          this.toast_opt.classname = 'toast-error';
          this.toast_opt.header = result?.status;
          this.toast.show(result?.message, this.toast_opt);
        } else if (result.status === 'error' && result.debug === null) {
          this.stopStreaming();
        } else {
          this.stream_status = "reconnecting";
          this.data_store_api.toggleReconnecting(true);
          this.stream_init(this.stream_id);

        }
        if (result?.message) {
          if (result?.status == "error" && result?.debug == null) {
            this.stopStreaming();
          }
          this.toast_opt.header = result?.status;
          this.toast.show(result?.message, this.toast_opt);
        } else {
          console.log("errrrr", result);
        }
      }
    });
  }
  ngAfterViewInit() {
    if (!this.data_store_api.getCookie('voco_first_time')) {
      this.modal.open(TourComponent, { ariaLabelledBy: 'modal-basic-title', centered: false, size: "md", windowClass: '' }).result.then(() => { }, (reason) => {
        document.cookie = "voco_first_time=true;"
      });
    }

    if (this.has_permision) {
      this.fire_api.syncClap(this.stream_id).snapshotChanges().subscribe((data: any) => data.map((c: any) => this.clap_count = c.payload.val()));
      if (this.stream_id !== 'completed') {
        this.get_stream_details(this.stream_id);
      } else {
        setTimeout(() => this.data_store_api.toggleLoader(false), 500);
      }
    }
  }
  ngOnInit() {
    this.data_store_api.currentBaseUrl.subscribe((url: string) => this.img_path = url + "assets/img/icons/");
  }

  ngAfterContentChecked() {
    this.streamcontext_status = stream?.webcast?.context?.state;
    navigator.mediaDevices.enumerateDevices()
      .then((devices) => {
        devices.forEach((device, key) => {
          if (device.kind === 'audioinput') {
            this.availMicDevices[key] = device;
            if (device.deviceId === 'default') {
              this.selmicDevice = device.label;
            }
          }
        });
      })
      .catch(function (err) {
        console.log(err.name + ": " + err.message);
      });

  }

}
