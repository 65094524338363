import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DataStoreService } from 'src/app/apis/data-storage/data-store.service';
import { StreamService } from 'src/app/apis/stream.service';


@Component({
  selector: 'app-streaming-detail',
  templateUrl: './streaming-detail.component.html',
  styleUrls: ['./streaming-detail.component.scss']
})
export class StreamingDetailComponent implements OnInit {

  @Input() stream_id = this.activatedRoute.snapshot.paramMap.get('stream-id');
  stream: any = [];
  stream_data: any;
  stream_url = 'live/';
  is_submitted: boolean = false;
  img_path: string = '';
  is_editing: boolean = false;
  readMore: boolean = true;
  constructor(
    private activatedRoute: ActivatedRoute,
    private route: Router,
    private stream_apis: StreamService,
    private modal: NgbModal,
    private data_store_api: DataStoreService
  ) {

  }

  streamNow() {
    this.modal.dismissAll();
    this.is_submitted = true;
    this.route.navigate(['/hosting/', this.stream_id]);
  }

  get_stream_copy_url() {
    const options = {
      url: this.stream_url,
      is_private: this.stream?.visibility === 'private',
      title: 'Streaming URL :',
      passcode_text: (this.stream?.passcode_text !== '') ? this.stream?.passcode_text : 'Pass code',
      passcode: this.stream?.post_password
    }
    
    if (options.passcode_text === undefined) {
      options.passcode_text = 'Pass code: ';
    }
    return options;
  }

  changeStatus(result: any) {
    if (result?.status) {
      this.is_editing = !this.is_editing;
      if (result?.data) {
        this.stream = result?.data;
      } else {
        this.stream_apis.displayCreatedStream(this.stream_id).subscribe((data: any) => this.stream = data);
      }
    } else {
      console.log("error to fetch updated data");

    }
  }

  ngOnInit(): void {
    this.data_store_api.currentBaseUrl.subscribe((url: string) => this.img_path = url + "assets/img/icons/");
    if (this.stream_id !== null) {
      this.stream_url = this.stream_url + this.stream_id;
      this.stream_apis.displayCreatedStream(this.stream_id).subscribe((data: any) => this.stream = data);
    }

  }

}
