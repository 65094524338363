import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-mainmenu',
  templateUrl: './mainmenu.component.html',
  styleUrls: ['./mainmenu.component.scss']
})
export class MainmenuComponent implements OnInit {
  @Input() menuLayout: any = "inline";
  menus = [
    // { name: 'Home', url: '', className: 'text-capitalize', linkClassName: '' },
    // { name: 'Explore', url: '', className: 'text-capitalize', linkClassName: '' },
    { name: 'Go live', url: '/hosting/new', className: '', linkClassName: 'bg-default btn btn-sm rounded p-0 pl-2 pr-2' }
  ]
  constructor() { }

  ngOnInit(): void {
  }

}
