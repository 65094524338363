import { Component, OnInit, Inject, HostListener } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { DataStoreService } from '../apis/data-storage/data-store.service';

@Component({
  selector: 'app-scroll-top',
  templateUrl: './scroll-top.component.html',
  styleUrls: ['./scroll-top.component.scss'],
  host: { 'class': 'd-block position-relative d-sm-inline' }
})
export class ScrollTopComponent implements OnInit {
  windowScrolled: boolean = false;
  img_path = '';
  constructor(@Inject(DOCUMENT) private document: Document, private data_store_api: DataStoreService) { }
  @HostListener("window:scroll", ['onWindowScroll'])
  onWindowScroll() {
    if (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop > 100) {
      this.windowScrolled = true;
    }
    else if (this.windowScrolled && window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop < 10) {
      this.windowScrolled = false;
    }
  }
  scrollToTop() {
    (function smoothscroll() {
      var currentScroll = document.documentElement.scrollTop || document.body.scrollTop;
      if (currentScroll > 0) {
        window.requestAnimationFrame(smoothscroll);
        window.scrollTo(0, currentScroll - (currentScroll / 8));
      }
    })();
  }

  ngOnInit(): void {
    this.data_store_api.currentBaseUrl.subscribe((url: string) => this.img_path = url + "assets/img/icons/");
  }

}
