import { Component, OnInit } from '@angular/core';
import { StreamService } from 'src/app/apis/stream.service';

@Component({
  selector: 'app-section1',
  templateUrl: './section1.component.html',
  styleUrls: ['./section1.component.scss']
})
export class Section1Component implements OnInit {
  list: any = {};
  stream_list: any = [];
  has_list = false;

  constructor(private stream_apis: StreamService) {
  }

  ngOnInit() {

    this.stream_apis.liveStreamers(13).subscribe((data: any) => {
      if (data?.status !== 'error') {
        this.stream_list = data;
        this.stream_list.map((item: any) => item.listen_url = '/live/' + item.stream_id);
        this.has_list = (data.length > 0) ? true : false;
      }
    });
  }

}
