import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Validators } from '@angular/forms';
import { FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { DataStoreService } from 'src/app/apis/data-storage/data-store.service';
import { SignupService } from 'src/app/apis/signup/signup.service';
import { ToastService } from 'src/app/apis/toast.service';


@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {
  @Output() changeSlide = new EventEmitter<string>();
  @Output() isSlider = new EventEmitter<boolean>();

  registerForm = new FormGroup({
    display_name: new FormControl('', [Validators.required]),
    email: new FormControl('', [Validators.required, Validators.email]),
    // description: new FormControl('', [Validators.required]),
    // user_meta: new FormControl('', [Validators.required]),
    password: new FormControl('', [Validators.required]),
    confirm_password: new FormControl('', [Validators.required]),
    accept_conditions: new FormControl('', [Validators.required, Validators.requiredTrue]),
    authcode: new FormControl('testcode'),
  });
  toast_opt = { classname: 'toast-success', header: " " };
  is_form_invalid: boolean = false;
  is_password_invalid: boolean = false;
  timezones = [];
  live_timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  img_path = '';
  is_submitted: boolean = false;
  constructor(
    private signup_api: SignupService,
    private toast: ToastService,
    private data_store_api: DataStoreService,
    private router: Router
    ) { }

  registerFormSubmit(rFormData: any) {
    this.is_form_invalid = (rFormData.untouched || rFormData.invalid) ? true : false;
    if (!this.is_form_invalid) {
      this.is_submitted = true;
      this.signup_api.userRegistration(rFormData.value).subscribe((data: any) => {
        this.toast_opt.header = data?.status;
        this.toast.show(data?.message, this.toast_opt);
        if (data.user.user_status === "0") {
          this.isSlider.emit(false);
        } else {
          this.router.navigate(['/signup', 'login']);
        }
        this.is_submitted = false;
      }, (error: any) => {
        console.log(error);
        this.toast_opt.classname = 'toast-error';
        this.toast_opt.header = error?.status;
        this.is_submitted = false;
        this.toast.show(error?.error?.data?.message, this.toast_opt);

      });
    }
  }

  passwordMatcher(pass: string, confirm_pass: string) {
    this.is_password_invalid = pass !== confirm_pass;
  }

  slideTo(slide: string) {
    this.changeSlide.emit(slide);
  }

  ngOnInit() {
    this.data_store_api.currentBaseUrl.subscribe((url: string) => this.img_path = url + "assets/img/icons/signup/");
    this.data_store_api.currentTimezones.subscribe(data => this.timezones = data);
  }
}
