import { Component, OnInit, ElementRef, Input, ViewChild, TemplateRef } from '@angular/core';
import { DataStoreService } from 'src/app/apis/data-storage/data-store.service';
import { ConfirmWindowComponent } from 'src/app/popups/confirm-window/confirm-window.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { PageService } from 'src/app/apis/page/page.service';
import { ToastService } from 'src/app/apis/toast.service';

@Component({
  selector: 'app-app-download',
  templateUrl: './app-download.component.html',
  styleUrls: ['./app-download.component.scss']
})
export class AppDownloadComponent implements OnInit {
  @ViewChild('sayHelloTemplate') sayHelloTemplate: ElementRef | undefined;

  img_path = "assets/img/png";
  app_list = [
    { link: "https://itunes.com/apps/appname", os: "ios", class_list: "", icon: "assets/img/png/app-store.png" },
    { link: "https://play.google.com/store/apps/details?id=", os: "android", class_list: "", icon: "assets/img/png/android.png" }
  ];
  app_download_formData = [
    { name: 'name', value: '', placeholder: 'Your name', required: true, validators: [Validators.required], classes: 'rounded' },
    { name: 'email', value: '', placeholder: 'Email', required: true, validators: [Validators.required, Validators.email], classes: 'rounded' },
    { type: 'hidden', name: 'os', value: '', required: true, validators: [Validators.required], classes: 'rounded' }];

  constructor(private data_store_api: DataStoreService, private pages_api: PageService, private modal: NgbModal, private toast: ToastService) { }
  show_download_form(os: any) {
    this.app_download_formData.filter(item => {
      if (item.name == 'os') {
        item.value = os;
      }
    });

    let modalRef = this.modal.open(ConfirmWindowComponent, {
      ariaLabelledBy: 'modal-basic-title',
      size: 'md',
      keyboard: false,
      centered: true,
      windowClass: 'confirm-box',
      backdropClass: 'confirm-box-backdrop'
    });
    modalRef.componentInstance.is_form = true;
    modalRef.componentInstance.classList = "text-center container";
    modalRef.componentInstance.message = `<h6><b>You're the first one to know...</b></h6>
    <h2 class="text-uppercase"><b>Get early access</b></h2>
    <p><b>Android & Iphone app will be shortly released. Stay tuned on our newsletter</b></p>`;
    modalRef.componentInstance.formData = this.app_download_formData;
    modalRef.componentInstance.confirm = "Get early access";
    modalRef.componentInstance.confirmClasses = "text-uppercase rounded";
    modalRef.componentInstance.cancel = "";
    modalRef.result.then(() => {
    }, (options: any) => {
      if (options?.email && options !== 0) {
        this.data_store_api.toggleLoader(true);
        this.pages_api.getMobileApp(options).subscribe((result: any) => {

          if (result.success) {
            this.toast.show("We will share the link as soon as possible", { classname: 'toast-success', header: '' });
          } else {
            this.toast.show("Something went wrong", { classname: 'toast-error', header: '' });
            console.log(result);

          }
        }, (err: any) => {
          console.log('err');
          console.log(err);
          this.toast.show(err.message, { classname: 'toast-error', header: 'Failed to sent' });
          this.data_store_api.toggleLoader(false);

        }, () => this.data_store_api.toggleLoader(false));

      }
    });

  }
  ngOnInit(): void {
    this.data_store_api.currentBaseUrl.subscribe((url: string) => {
      this.img_path = url + this.img_path;
      this.app_list.filter((item: any) => item.icon = url + item.icon);
    });
  }

}
