import { Component, OnInit } from '@angular/core';
import { NgbDropdownConfig } from '@ng-bootstrap/ng-bootstrap';
import { DataStoreService } from 'src/app/apis/data-storage/data-store.service';
import { ToastService } from 'src/app/apis/toast.service';

@Component({
  selector: 'app-lang-opt',
  templateUrl: './lang-opt.component.html',
  styleUrls: ['./lang-opt.component.scss']
})
export class LangOptComponent implements OnInit {
  active_lang = { name: "English", value: "en" };
  lang_list = [
    { name: "English", value: "en" },
    { name: "हिन्दी", value: "hi" },
    { name: "മലയാളം", value: "ml" },
    { name: "తెలుగు", value: "te" }
  ];
  lang_options: any = [];

  constructor(
    private config: NgbDropdownConfig,
    private toast: ToastService,
    private data_store_api: DataStoreService,
  ) {
    this.config.placement = 'top-right, bottom-right';
    this.config.autoClose = true;
    this.data_store_api.currentContentLang.subscribe((lang_val: any) => {
      if (lang_val) {
        this.active_lang = this.lang_list.filter((lang: any) => lang.value === lang_val)[0];
      } else {
        this.active_lang = this.lang_list.filter((lang: any) => lang.value === this.active_lang.value)[0];
      }
    });
  }

  changeLang(value: string) {
    this.lang_options = this.lang_list.filter((lang: any) => lang.value !== value);
    this.active_lang = this.lang_list.filter((lang: any) => lang.value === value)[0];
    this.data_store_api.updateContentLang(value);
    localStorage.setItem('vlContentLang', value);
    this.toast.show("content language changed.", { classname: "toast-success" });
  }

  ngOnInit(): void {
    this.lang_options = this.lang_list.filter((item: any) => item.value !== this.active_lang.value);
  }
}
