<div *ngIf="status === 'error'" class="empty-text h-100 unselectable">
    <p [innerHTML]="error"></p>
</div>
<div class="col-sm-12 h-100">

    <div class="card-deck thin-scrollbar responsive" *ngIf="list">
        <div *ngFor="let item of list" class="mb-5 p-0 completed-card">
            <div class="card d-md-flex d-sm-flex flex-md-row flex-sm-column">
                <div class="card-inner col-sm-12 col-md-5 p-0">
                    <div class="card-header text-nowrap">{{ item.streamtime | date: "dd-MM-y | h:mm a" }}</div>
                    <div class="additional-options card-top-menu">
                        <button *ngIf="item.stream_id" class="delete-opt btn"
                            (click)="copyUrl('url copied Successfully')"
                            [cdkCopyToClipboard]="stream_detail_url + item.stream_id">
                            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                                id="Group_2656" data-name="Group 2656" width="22.275" height="24.3"
                                viewBox="0 0 22.275 24.3">
                                <defs>
                                    <clipPath id="clip-path">
                                        <rect id="Rectangle_2293" data-name="Rectangle 2293" width="22.275"
                                            height="24.3" fill="#fff" />
                                    </clipPath>
                                </defs>
                                <g id="Group_2656-2" data-name="Group 2656" clip-path="url(#clip-path)">
                                    <path id="Path_3206" data-name="Path 3206"
                                        d="M8.859,19.743a4.309,4.309,0,0,1-4.3-4.3V5.062H2.784A2.787,2.787,0,0,0,0,7.847V21.515A2.787,2.787,0,0,0,2.784,24.3H15.44a2.787,2.787,0,0,0,2.784-2.784V19.743ZM22.275,2.784A2.784,2.784,0,0,0,19.49,0H8.859A2.784,2.784,0,0,0,6.075,2.784V15.44a2.784,2.784,0,0,0,2.784,2.784H19.49a2.784,2.784,0,0,0,2.784-2.784Z"
                                        fill="#E71C4C" />
                                </g>
                            </svg>
                        </button>
                    </div>
                    <img (click)="redirect(item.stream_id)" class="card-img-top" src="{{img_path}}audio_wave.svg"
                        alt="Card image cap">
                    <div class="additional-options card-menu">
                        <button (click)="removeStreaming(item.id, item.stream_id)" class="delete-opt btn">
                            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                                viewBox="0,0,256,256" width="32px" height="32px" fill-rule="nonzero">
                                <g fill-rule="nonzero" stroke="none" stroke-width="1" stroke-linecap="butt"
                                    stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray=""
                                    stroke-dashoffset="0" font-family="none" font-weight="none" font-size="none"
                                    text-anchor="none" style="mix-blend-mode: normal">
                                    <g transform="scale(8,8)">
                                        <path
                                            d="M15,4c-0.52344,0 -1.05859,0.18359 -1.4375,0.5625c-0.37891,0.37891 -0.5625,0.91406 -0.5625,1.4375v1h-6v2h1v16c0,1.64453 1.35547,3 3,3h12c1.64453,0 3,-1.35547 3,-3v-16h1v-2h-6v-1c0,-0.52344 -0.18359,-1.05859 -0.5625,-1.4375c-0.37891,-0.37891 -0.91406,-0.5625 -1.4375,-0.5625zM15,6h4v1h-4zM10,9h14v16c0,0.55469 -0.44531,1 -1,1h-12c-0.55469,0 -1,-0.44531 -1,-1zM12,12v11h2v-11zM16,12v11h2v-11zM20,12v11h2v-11z">
                                        </path>
                                    </g>
                                </g>
                            </svg>
                        </button>

                    </div>
                </div>
                <div class="col-sm-12 col-md-7 pr-0">
                    <h3 class="title text-nowrap" [ngbTooltip]="item.title" placement="bottom"
                        tooltipClass="my-tooltip">{{
                        item.title }}</h3>
                    <p style="height: 20px; margin-bottom: 0px;" class="passcode">Code {{ item.passcode }}</p>
                    <div class="info">
                        <span>
                            <span class="chat" tooltipClass="completed-stream-opt"
                                ngbTooltip="{{ item.messages_count }} messages"><img src="{{img_path}}chat.svg" alt="">
                                {{
                                item.messages_count }}</span>
                        </span>
                        <span>
                            <span class="listeners" tooltipClass="completed-stream-opt"
                                ngbTooltip="{{ item.listeners }} listeners"><img src="{{img_path}}hear.svg" alt=""> {{
                                item.listeners }}</span>
                        </span>
                        <span>
                            <span class="claps" tooltipClass="completed-stream-opt"
                                ngbTooltip="{{ item.claps }} claps"><img src="{{img_path}}clap_fill.svg" alt=""> {{
                                item.claps }}</span>
                        </span>
                        <span>
                            <span class="followers" tooltipClass="completed-stream-opt"
                                ngbTooltip="{{ item.followers }} followers"><img src="{{img_path}}star_fill.svg" alt="">
                                {{
                                item.followers }}</span>
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>