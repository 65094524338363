import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { Router, ActivatedRoute } from '@angular/router';
import { DataStoreService } from 'src/app/apis/data-storage/data-store.service';
import { ChatService } from 'src/app/apis/firebase/chat.service';
import { SignupService } from 'src/app/apis/signup/signup.service';
import { ToastService } from 'src/app/apis/toast.service';

@Component({
  selector: 'app-settingsmenu',
  templateUrl: './settingsmenu.component.html',
  styleUrls: ['./settingsmenu.component.scss']
})
export class SettingsmenuComponent implements OnInit {

  logged_in: boolean = false;
  user_token: string = '';
  user_details: any;
  popup: boolean = false;
  img_path: string = '';
  is_streaming_ongoing: boolean = false;
  streaming_btn = {
    text: 'Go live', link: '/hosting/new'
  }
  streaming_btn_status: boolean = false;
  constructor(
    private signup_api: SignupService,
    private data_store_api: DataStoreService,
    private route: Router,
    private activRoute: ActivatedRoute,
    private fire_api: ChatService,
    private toast: ToastService,
    private fireAuth: AngularFireAuth
  ) {
  }

  userLogout() {
    this.data_store_api.toggleLoader(true);
    this.signup_api.userLogout(this.user_token).subscribe((data: any) => {
      console.log(data);
      if (data.success) {
        localStorage.removeItem('sessionUserToken');
        localStorage.removeItem('user_details');
        this.data_store_api.updateUserDetails('');
        this.data_store_api.updateUserToken('');
        this.popup = false;
        // this.toast.show("User has been logged out successfully...", { classname: 'toast-success', header: "User logged out" })

      } else {
        console.log(data);
        this.toast.show("Failed to logout", { classname: 'toast-error', header: "Something went wrong" });
        this.data_store_api.toggleLoader(false);
      }
    }, (error: any) => {
      console.log(error);
      this.toast.show("Failed to logout", { classname: 'toast-error', header: "Something went wrong" });
      this.data_store_api.toggleLoader(false);

    }, () => {
      this.data_store_api.toggleLoader(false);
      this.route.navigate(['']);
    });
  }

  ngOnInit(): void {
    this.data_store_api.currentBaseUrl.subscribe((url: string) => this.img_path = url + "assets/img/icons");
    this.activRoute.url.subscribe(url => {
      if ((url[0]?.path !== 'hosting' && url[0]?.path !== 'live') || !url[0]?.path) {
        this.streaming_btn_status = true;
      }
    });
    if (localStorage.getItem('sessionUserToken') !== null) {

      this.fire_api.loginFirebase(JSON.stringify(localStorage.getItem('sessionUserToken'))).subscribe((data: any) => {
        this.fireAuth.signInWithCustomToken(data.data.token).then((userCredential) => {
          console.log();
          if (userCredential.user?.uid) {
            this.data_store_api.getLiveStreamingId.subscribe((stream_id: any) => {
              if (stream_id !== null && stream_id !== '' && stream_id !== 'completed' && stream_id !== 'not-found') {
                this.fire_api.syncStreamStatus(stream_id).snapshotChanges().subscribe((statusData: any) => {
                  statusData.map((c: any) => {
                    this.is_streaming_ongoing = c.payload.val() === 'live';
                    if (this.is_streaming_ongoing) {
                      this.streaming_btn.link = "/hosting/" + stream_id;
                    }
                  });
                });
              } else {
              }
            });

          }
        })
          .catch((error) => {
            console.log(error);
          });
      }, (error: any) => console.log(error));
    }
    this.data_store_api.currentUserDetails.subscribe(details => {
      if (details) {
        this.user_details = details;
        this.user_details = JSON.parse(this.user_details);
        let usr_token: any;
        this.data_store_api.currentUserToken.subscribe(token => {
          this.logged_in = (token) ? true : false;
          usr_token = token;
          this.user_token = usr_token;
        });
      }
    });
  }
}