import { Component, OnInit } from '@angular/core';
import { DataStoreService } from './apis/data-storage/data-store.service';
import { StreamService } from './apis/stream.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'Vocoloop';
  timezone_list: any;
  isLoading = false;
  isReconnecting = true;
  constructor(
    private data_store_api: DataStoreService,
    private stream_apis: StreamService,
    translate: TranslateService
  ) {

    translate.addLangs(['en', 'ml', 'ta', 'te']);
    translate.setDefaultLang('en');
    this.data_store_api.currentContentLang.subscribe((content: any) => {
      if (content !== null) {
        translate.use(content);
      }
    });
  }

  ngOnInit() {
    this.data_store_api.currentLoaderStatus.subscribe((status: boolean) => this.isLoading = status);
    this.data_store_api.currentReconnectingStatus.subscribe((status: boolean) => this.isReconnecting = status);
    this.data_store_api.currentTimezones.subscribe((data: any) => {
      if (data.length === 0) {
        this.stream_apis.getTimeZones().subscribe((details: any) => {
          this.timezone_list = details;
          this.data_store_api.updateTimeZones(this.timezone_list.timezones)
        });
      }
    });

  }
}

