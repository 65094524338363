import { Component, OnInit, OnChanges, Input, Output, EventEmitter } from '@angular/core';
import { StreamService } from 'src/app/apis/stream.service';
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { Router } from '@angular/router';
import { ToastService } from 'src/app/apis/toast.service';
import { DataStoreService } from 'src/app/apis/data-storage/data-store.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';


@Component({
  selector: 'app-streaming-form',
  templateUrl: './streaming-form.component.html',
  styleUrls: ['./streaming-form.component.scss']
})
export class StreamingFormComponent implements OnInit, OnChanges {

  @Input() btn_text: string = 'Submit';
  @Input() is_update: boolean = false;
  @Input() stream_content: any = [];

  @Output() is_updated = new EventEmitter<any>();

  stream_categories: any;
  new_streaming = new FormGroup({
    title: new FormControl('', [Validators.required]),
    content: new FormControl('', [Validators.required]),
    categoryid: new FormControl(''),
    livetime: new FormControl(''),
    hide_chat: new FormControl(false), // true as 1 or false as 0
    visibility: new FormControl(false),  // private or public
    status: new FormControl('live'), // live or scheduled
  });
  scheduled = new FormControl(false);
  stream_id: string = "";
  is_schedule = (this.new_streaming.value.status === 'scheduled') ? true : false;
  is_submitted: boolean = false;
  toast_opt = { classname: 'toast-success', header: ' ' };
  schedule_btn_text = 'Schedule event';
  submit_btn_text = 'Submit';
  img_path = '';
  tooltip_pos = "right";
  constructor(
    private stream_api: StreamService,
    public toast: ToastService,
    private route: Router,
    private data_store_api: DataStoreService,
    private modal: NgbModal
  ) { }
  streaming_submit() {
    this.new_streaming.value.hide_chat = (this.new_streaming.value.hide_chat) ? 1 : 0;
    this.new_streaming.value.visibility = (this.new_streaming.value.visibility) ? 'private' : 'public';
    this.is_submitted = true;
    if (this.is_update) {
      return this.update_stream(this.new_streaming.value);
    } else {
      return this.new_stream(this.new_streaming.value);
    }

  }
  new_stream(stream_details: any) {
    this.stream_api.createStream(stream_details).subscribe((stream_submit_result: any) => {
      this.stream_id = stream_submit_result.stream_id;
      this.is_submitted = false;
      if (this.stream_id !== '' && stream_submit_result?.status === "success") {
        this.toast_opt.header = stream_submit_result?.status;
        if (this.is_schedule) {
          this.route.navigateByUrl("dashboard#scheduled");
        } else {
          localStorage.setItem('vocoStreamId', this.stream_id);
          this.data_store_api.updateLiveStreamingId(this.stream_id);
          this.route.navigateByUrl("hosting/view/" + this.stream_id);
        }
      } else {
        this.toast_opt.classname = 'toast-error';
        if (this.stream_id === '') {
          stream_submit_result.message = "stream id is missing";
          this.toast_opt.header = "error";
        }
      }
      this.toast.show(stream_submit_result?.message, this.toast_opt);
      window.scroll(0, 0);
    });
  }

  update_stream(stream_details: any) {
    stream_details.stream_id = this.stream_content.stream_id;
    this.stream_api.updateStreams(stream_details).subscribe((update_result: any) => {
      this.toast.show(update_result?.message, this.toast_opt);
      this.is_submitted = false;
      this.modal.dismissAll();
      if (this.is_schedule) {
        this.route.navigateByUrl("dashboard#scheduled");
      } else {
        localStorage.setItem('vocoStreamId', this.stream_id);
        this.data_store_api.updateLiveStreamingId(this.stream_id);
        this.is_updated.emit({status: true, data: update_result?.data});
      }
    })
  }

  updateStreamTiming(status: boolean) {
    this.scheduled.setValue(status);
    this.is_schedule = status;
    this.new_streaming.patchValue({ status: status ? 'scheduled' : 'live' });
    if (!this.is_update) {
      this.changeBtnText();
    }
  }

  changeBtnText() {
    if (this.btn_text === this.schedule_btn_text) {
      this.btn_text = this.submit_btn_text;
    } else {
      this.btn_text = this.schedule_btn_text;
    }
  }

  getCookie(key: string) {
    let result = document.cookie.split(";");
    let cookies: any = {};
    result.map((item: any) => item.trim().split('=')).filter(([key, value]) => cookies[key] = value);
    return cookies[key];


  }
  ngOnInit() {
    // this.data_store_api.toggleLoader(true);
    
    this.data_store_api.currentBaseUrl.subscribe((url: string) => this.img_path = url + "assets/img/icons/");
    this.data_store_api.currentStreamingCategories.subscribe((categories: any) => {
      if (!this.getCookie('vocoStreamCategories')) {
        this.stream_api.getAllCategories().subscribe((cate_list: any) => {
          this.stream_categories = cate_list;
          let new_date: any = new Date();
          new_date = new_date.setDate(new_date.getDate() + 1);
          this.data_store_api.updateStreamingCategories(JSON.stringify(cate_list));
          localStorage.setItem('vocoStreamCategories', JSON.stringify(cate_list));
          document.cookie = "vocoStreamCategories=registered; expires=" + new_date + ";";
          this.data_store_api.toggleLoader(false);
        });
        
      } else {
        this.stream_categories = JSON.parse(categories);
        this.data_store_api.toggleLoader(false);
      }
    });
  }

  ngOnChanges() {
    if (this.is_update) {
      if (Object.values(this.stream_content).length > 0) {

        let cat_id = Object.values(this.stream_content.category).map((item: any) => item = item.term_id);
        this.scheduled.setValue((this.stream_content.stream_status === 'scheduled') ? false : true);
        this.new_streaming.patchValue({
          title: this.stream_content.stream_title,
          content: this.stream_content.stream_desc,
          categoryid: cat_id[0],
          livetime: new Date(this.stream_content.live_date).toJSON(),
          hide_chat: (this.stream_content.hide_chat === 1) ? true : false,
          visibility: (this.stream_content.visibility === 'private') ? true : false,
          status: this.stream_content.stream_status
        });
        this.updateStreamTiming(this.stream_content?.stream_status === 'scheduled');
      }

    }
  }

}
