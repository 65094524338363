import { Component, OnInit } from '@angular/core';
import { NgbCarouselConfig } from '@ng-bootstrap/ng-bootstrap';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { DataStoreService } from 'src/app/apis/data-storage/data-store.service';
import { ToastService } from 'src/app/apis/toast.service';


declare const window: { clipboardData: any; };
@Component({
  selector: 'app-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.scss']
})
export class BannerComponent implements OnInit {
  stream_id = new FormControl('');
  toast_opt = { classname: 'toast-success', header: " " };
  images = ['1.jpg', '2.jpg', '1.jpg', '2.jpg'];
  constructor(
    config: NgbCarouselConfig,
    public router: Router,
    private toast: ToastService,
    private data_store_api: DataStoreService
  ) {
    config.wrap = false;
  }

  paste_link(event: ClipboardEvent) {
    let clipboardData = event.clipboardData || window.clipboardData;
    clipboardData = clipboardData.getData('text');

    if (clipboardData.includes(location.origin)) {
      location.href = clipboardData;
    } else {
      this.router.navigate(["live", clipboardData]);
    }
  }
  type_link() {
    if (this.stream_id.value.replace(/\s/g, '') !=='') {
      if (this.stream_id.value.includes(location.origin)) {
        location.href = this.stream_id.value;
      } else {
        this.router.navigate(["live", this.stream_id.value]);
      }
    } else {
      this.toast_opt.classname = 'toast-error';
          this.toast_opt.header = '';
          this.toast.show('Please enter valid code', this.toast_opt);
    }
  }
  ngOnInit(): void {
    this.data_store_api.currentBaseUrl.subscribe((url: string) => {
      this.images = this.images.map((item: any) => item = url + 'assets/img/bg/' + item);
    });
    
  }

}
