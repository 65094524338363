<ng-container *ngIf="!is_loading; else loading">
    <div class="modal-header flex-column">
        <span [ngbTooltip]="details.tooltip || ''" placement="bottom left" [autoClose]="false" triggers="manual"
            #otpVerification="ngbTooltip">
            {{details.title || 'Enter private room key'}}
            <svg (click)="otpVerification.toggle()" (blur)="otpVerification.close()" class="tooltip-help-icon"
                xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#2b80d3">
                <path d="M0 0h24v24H0z" fill="none" />
                <path
                    d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 17h-2v-2h2v2zm2.07-7.75l-.9.92C13.45 12.9 13 13.5 13 15h-2v-.5c0-1.1.45-2.1 1.17-2.83l1.24-1.26c.37-.36.59-.86.59-1.41 0-1.1-.9-2-2-2s-2 .9-2 2H8c0-2.21 1.79-4 4-4s4 1.79 4 4c0 .88-.36 1.68-.93 2.25z" />
            </svg>
        </span>
        <p *ngIf="otp_status.error" class="text-error">{{otp_status.message}}</p>
    </div>
    <form [formGroup]="otpForm" (ngSubmit)="verifyOtp(otpForm.value)">
        <div class="modal-content">
            <div class="modal-body">
                <div class="form-content">
                    <input #otp1 (paste)="updateVal($event)" (keyup)="focusInput($event, otp2, otp1)" type="text"
                        maxlength="2" min="0" max="9" formControlName="otp1" placeholder="-" name="" id="">
                    <input #otp2 (paste)="updateVal($event)" (keyup)="focusInput($event, otp3, otp1)" type="text"
                        maxlength="2" min="0" max="9" formControlName="otp2" placeholder="-" name="" id="">
                    <input #otp3 (paste)="updateVal($event)" (keyup)="focusInput($event, otp4, otp2)" type="text"
                        maxlength="2" min="0" max="9" formControlName="otp3" placeholder="-" name="" id="">
                    <input #otp4 (paste)="updateVal($event)" (keyup)="focusInput($event, otp4, otp3)" type="text"
                        maxlength="2" min="0" max="9" formControlName="otp4" placeholder="-" name="" id="">
                </div>

            </div>
        </div>
    </form>
</ng-container>

<ng-template #loading>
    <div class="modal-content is-loading">
        <div class="loader-panel">
            <div class="spinner-border"></div>
        </div>
    </div>
</ng-template>