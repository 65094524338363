import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-tour',
  templateUrl: './tour.component.html',
  styleUrls: ['./tour.component.scss']
})
export class TourComponent implements OnInit {
  stage: number = 1;
  max_stages: number = 3;
  constructor(private modalRef: NgbActiveModal) { }

  ngOnInit(): void {

  }
  toggleContent(content: number) {
    this.stage = content;
    if (content === 0) {
      this.modalRef.dismiss("completed");
    }
  }

}
