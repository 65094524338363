import { Component } from '@angular/core';
import { DataStoreService } from '../apis/data-storage/data-store.service';

export function isMobile() {
  return (
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile/i.test(navigator.userAgent) &&
    'ontouchstart' in window
  );
}
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent {
  token: string = '';
  is_logged_in: boolean = false;
  is_mobile: boolean = isMobile();
  constructor(
    private data_store_api: DataStoreService
    ) {
    this.data_store_api.toggleLoader(false);
    this.data_store_api.currentUserToken.subscribe((token: any) => {
      this.token = token;
      this.is_logged_in = (this.token !== '' && this.token !== null && this.token !== undefined);
    });
    
  }
  
}
