import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DataStoreService } from 'src/app/apis/data-storage/data-store.service';


@Component({
  selector: 'app-private-streaming-unknown-user',
  templateUrl: './private-streaming-unknown-user.component.html',
  styleUrls: ['./private-streaming-unknown-user.component.scss']
})
export class PrivateStreamingUnknownUserComponent implements OnInit {
  @Input() redir_url: string = '/';

  slide: any = "login";
  img_path = '';
  constructor(private data_store_api: DataStoreService, private route: ActivatedRoute) {
  }


  ngOnInit(): void {
    this.data_store_api.currentBaseUrl.subscribe((url: string) => this.img_path = url + "assets/img/icons/");
  }

}
