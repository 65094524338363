<form [formGroup]="registerForm">
    <div class="form-group" [ngClass]="{'input-error': registerForm.controls.display_name.invalid && (registerForm.controls.display_name.dirty || registerForm.controls.display_name.touched || is_form_invalid)}">
        <label for="fullname">Name</label>
        <input type="text" class="form-control" formControlName="display_name" id="fullname" placeholder="Full name">
        <p class="text-error" *ngIf="registerForm.controls.display_name.errors?.required"> Display name is required</p>
    </div>
    <div class="form-group" [ngClass]="{'input-error': registerForm.controls.email.invalid && (registerForm.controls.email.dirty || registerForm.controls.email.touched || is_form_invalid)}">
        <label for="email">Email address</label>
        <input type="text" class="form-control" formControlName="email" id="email" placeholder="E-mail">
        <p class="text-error" *ngIf="registerForm.controls.email.errors?.required"> E-mail is required</p>
        <p class="text-error" *ngIf="registerForm.controls.email.errors?.email"> E-mail format only</p>
    </div>
    <!-- <div class="form-group" [ngClass]="{'input-error': registerForm.controls.description.invalid && (registerForm.controls.description.dirty || registerForm.controls.description.touched || is_form_invalid)}">
        <label for="bio">Bio</label>
        <textarea formControlName="description" id="" cols="10" class="form-control" placeholder="Bio"></textarea>
        <p class="text-error" *ngIf="registerForm.controls.description.errors?.required"> Description is required</p>
    </div>
    <div class="form-group" [ngClass]="{'input-error': registerForm.controls.user_meta.invalid && (registerForm.controls.user_meta.dirty || registerForm.controls.user_meta.touched || is_form_invalid)}">
        <label for="timezone">Timezone </label>
        <select class="form-control" formControlName="user_meta">
            <option *ngFor="let timezone of timezones" [value]="timezone">{{ timezone }}</option>
        </select>
        <p class="text-error" *ngIf="registerForm.controls.user_meta.errors?.required"> Timezone is required</p>
    </div> -->
    <div class="form-group" [ngClass]="{'input-error': registerForm.controls.password.invalid && (registerForm.controls.password.dirty || registerForm.controls.password.touched || is_form_invalid)}">
        <label for="password">Password</label>
        <input type="password" class="form-control" formControlName="password" id="password" placeholder="Password">
        <p class="text-error" *ngIf="registerForm.controls.password.errors?.required"> Password is required</p>
    </div>
    <div class="form-group" [ngClass]="{'input-error': registerForm.controls.confirm_password.invalid && (registerForm.controls.confirm_password.dirty || registerForm.controls.confirm_password.touched || is_form_invalid) || is_password_invalid}">
        <label for="confirm">Confirm password</label>
        <input type="password" class="form-control" formControlName="confirm_password" (keyup)="passwordMatcher(registerForm.value.password, registerForm.value.confirm_password)" id="confirm" placeholder="Re-enter password">
        <p class="text-error" *ngIf="registerForm.controls.confirm_password.errors?.required"> Confirm password is required
        </p>
        <p class="text-error" *ngIf="is_password_invalid"> Confirm password not matching</p>
    </div>
    <div class="form-group" [ngClass]="{'input-error': registerForm.controls.accept_conditions.invalid && (registerForm.controls.accept_conditions.dirty || registerForm.controls.accept_conditions.touched || is_form_invalid)}">
        <label for="terms">
            <input type="checkbox" name="terms" formControlName="accept_conditions" id="terms">
            I accept the <a href="https://vocoloop.com/app/terms-and-conditions/" target="_blank">Terms and Conditions</a>
        </label>
        <p class="text-error" *ngIf="registerForm.controls.accept_conditions.errors?.required"> Terms & conditions required
        </p>
    </div>
    <div class="action-container" [ngClass]="{'submitted': is_submitted}">
        <div class="button-group d-block">
            <button (click)="registerFormSubmit(registerForm)" class="w-100 btn-fill button text-uppercase">Sign Up</button>
            <p class="text-center">Already have an account? <br> <a routerLink="/signup/login">Login here</a></p>
            <!-- <button (click)="slideTo('login')" class="btn-outline button text-uppercase text-center">Login</button> -->
        </div>
        <span class="spinner-border loader"></span>
    </div>
    <!-- <p class="text-uppercase m-4 text-center">Or continue with</p>
    <div class="social-login-section">
        <ul class="list-inline text-center">
            <li class="list-inline-item">
                <a routerLink=""><img src="{{img_path}}gmail.svg" alt=""></a>
            </li>
            <li class="list-inline-item">
                <a routerLink=""><img src="{{img_path}}twiter.svg" alt=""></a>
            </li>
            <li class="list-inline-item">
                <a routerLink=""><img src="{{img_path}}facebook.svg" alt=""></a>
            </li>
        </ul>
    </div> -->
</form>