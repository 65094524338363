<div class="container pt-sm-5 pb-sm-5">
    <div class="row">
        <div class="col-sm-4">
            <div class="align-items-center d-flex pb-4 pt-4">
                <div class="stream-status">
                    <p [ngClass]="{'animation-active': (stream_status === 'waiting' || stream_status === 'connecting'), 'active': is_live}"
                        class="stream-status-icon active {{stream_status}}-icon text-loading-animation animation-active">
                        {{ 'stream.status.' + stream_status | translate }}</p>
                </div>
                <div class="listeners-count"><img *ngIf="img_path!==''" src="{{img_path}}hear_white.svg" alt=""><span>{{
                        listeners_count }}</span></div>
                <div class="listeners-count"><img *ngIf="img_path!==''" src="{{img_path}}clap_fill_White.svg"
                        alt=""><span>{{
                        clap_count }}</span>
                </div>
            </div>
            <div class="hoster-container text-center text-md-left">
                <div class="user-profile image-responsive">
                    <img class="rounded-circle" [src]="stream?.avatar_icon" [alt]="stream?.display_name">
                </div>
                <div class="image-description">
                    <p class="justify-content-center justify-content-sm-start profile-title">
                        <span [title]="stream?.display_name" [innerHTML]="stream?.display_name"></span>
                        <img *ngIf="stream?.author_details.user_varified !== 'no' && img_path !==''"
                            src="{{img_path}}tick_verified.svg" alt="verified">
                    </p>
                </div>
            </div>
            <div class="streaming-container text-center text-sm-left">
                <div class="program-title">
                    <h2 [innerHTML]="stream?.stream_title"></h2>
                </div>
                <div class="program-desc">
                    <p *ngIf="readMore">
                        {{ stream?.stream_desc | slice:0:250 }}<span *ngIf="stream?.stream_desc.length > 250">...</span>
                        <span *ngIf="stream?.stream_desc.length > 250" class="read-more-action"
                            (click)="readMore=!readMore" [innerHTML]="'showMore' | translate">Show more</span>
                    </p>
                    <p *ngIf="!readMore">
                        {{ stream?.stream_desc}}
                        <span class="read-more-action" (click)="readMore=!readMore"
                            [innerHTML]="'showLess' | translate">Show less</span>
                    </p>
                </div>
                <div class="button-group justify-content-center justify-content-sm-start">
                    <button (click)="clap()" [disabled]="!is_loggedin || is_clapped">
                        <img *ngIf="img_path !==''" src="{{img_path}}clap_outline.svg" alt="">
                        <span>{{ 'stream.actions.clap' | translate }}</span>
                    </button>
                    <button class="bg-transparent" (click)="follow_streamer(is_follow)" [disabled]="!is_loggedin">
                        <!-- <img src="{{img_path}}ti" alt=""> -->
                        <span
                            [innerHTML]="(this.is_follow ? 'stream.actions.unfollow' : 'stream.actions.follow') | translate"></span>
                    </button>
                </div>
                <p *ngIf="!is_loggedin" class="pt-3 pb-3 mb-0"><span class="text-danger note-text">*</span> {{
                    'stream.actions.note' | translate}}</p>
            </div>
        </div>
        <div *ngIf="this.stream_status !=='scheduled'; else scheduled_counter"
            class="col-sm-4 wave-base {{ this.stream_status }}">
            <div>
                <audio [src]="station_url" #player id="audio_base"></audio>
            </div>
            <canvas #wavebase id="wavebase"></canvas>
        </div>
        <ng-template #scheduled_counter>
            <div *ngIf="this.stream_status ==='scheduled'" class="col-sm-4 wave-base {{ this.stream_status }}">
                <p [innerHTML]="schedule_count"></p>
                <div class="count-icon">
                    <div class="icon-inner"></div>
                </div>
            </div>
        </ng-template>
        <div class="col-sm-4 d-none d-sm-block">
            <app-chat *ngIf="!is_chat_hide"></app-chat>
        </div>
    </div>
</div>
<div *ngIf="stream?.stream_status === 'live'" class="footer-container">
    <div class="container">
        <div *ngIf="!is_playing" class="button-group justify-content-center player-control-panel play-container">
            <button (click)="play()"><img *ngIf="img_path !==''" src="{{img_path}}hosting_play.svg" alt=""></button>
        </div>
        <div *ngIf="is_playing" class="button-group player-control-panel played-container">
            <button (click)="pause()">
                <svg xmlns="http://www.w3.org/2000/svg" width="89" height="86" viewBox="0 0 89 86">
                    <g id="Group_2697" data-name="Group 2697" transform="translate(0.379 -0.21)">
                        <rect id="Rectangle_2603" data-name="Rectangle 2603" width="89" height="86" rx="43"
                            transform="translate(-0.379 0.21)" fill="transparent" />
                        <rect id="Rectangle_2604" data-name="Rectangle 2604" width="36" height="38" rx="8"
                            transform="translate(25.621 24.21)" fill="#e71c4c" />
                    </g>
                </svg>
            </button>
            <div class="stream-counter mr-3">{{ elapsed_time }}</div>
            <div class="popup-container">

                <button (click)="control_vol(1, 'high')" *ngIf="vol_level === 'mute' && img_path !== ''"><img
                        src="{{img_path}}volume_mute.svg" alt=""></button>
                <button (click)="control_vol(0, 'mute')" *ngIf="vol_level === 'high' && img_path !==''"><img
                        src="{{img_path}}volume_high.svg" alt=""></button>
            </div>
        </div>
    </div>

</div>

<!-- mobile chat popup -->
<div class="d-md-none">
    <div class="chat-icon-popup cursor-pointer" [ngClass]="{'active': !chat_popup}" (click)="chat_popup=true">
        <img *ngIf="img_path !==''" src="{{img_path}}/chat.svg" alt="">
    </div>
    <div class="chat-popup-window" [ngClass]="{'active': chat_popup}">
        <button type="button" (click)="chat_popup=false" class="close" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
        <app-chat *ngIf="!is_chat_hide"></app-chat>
    </div>
</div>

<!-- schema section starts here -->
<ng-container *ngFor="let shema of schemas">
    <script type="application/ld+json">
    {{shema | json}}
    </script>
</ng-container>
<!-- schema section ends here -->