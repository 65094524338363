<div class="detail-editable-box">
    <app-streaming-form class="edit-stream-form toggle-section" (is_updated)="changeStatus($event)"
        [is_update]="is_editing" [btn_text]="'update'" [stream_content]="stream"
        [ngClass]="{'active': is_editing}"></app-streaming-form>
    <div class="edit-action d-flex cursor-pointer" (click)="is_editing = !is_editing">
        <span class="unselectable toggle-section-left" [ngClass]="{'active': !is_editing}">
            <img src="{{img_path}}edit.svg" alt="">{{'stream.detail.actionBtn' | translate}}
        </span>
        <span class="unselectable toggle-section-left" [ngClass]="{'active': is_editing}">
            <img src="{{img_path}}edit.svg" alt="">
            {{'stream.detail.dismissBtn' | translate}}
        </span>
    </div>
    <div class="stream-details toggle-section" [ngClass]="{'active': !is_editing}">
        <div class="stream-title">
            <h3>{{ stream?.stream_title }}</h3>
        </div>
        <div class="category-list">
            <span>Category: </span>
            <span *ngFor="let cat of stream?.category" class="category-item">{{ cat.name }}</span>
        </div>
        <div class="description">
            <p *ngIf="readMore">{{ stream?.stream_desc | slice:0:250 }}<span *ngIf="stream?.stream_desc?.length > 250">...</span>
                <span *ngIf="stream?.stream_desc?.length > 250" class="read-more-action" (click)="readMore=!readMore" [innerHTML]="'showMore' | translate">Show
                    more</span>
            </p>
            <p *ngIf="!readMore">{{ stream?.stream_desc }}
                <span class="read-more-action" (click)="readMore=!readMore" [innerHTML]="'showLess' | translate">Show
                    less</span>
            </p>
        </div>
        <div class="schedule-date">
            <p>{{ stream?.live_date | date: "dd-MM-y | h:mm a" }}</p>
        </div>
    </div>
</div>
<app-copy-streamurl class="toggle-section d-block" [ngClass]="{'active': !is_editing}"
[streamurl_data]="get_stream_copy_url()"></app-copy-streamurl>

<div class="stream-option text-center toggle-section" [ngClass]="{'active': !is_editing}">
    <a class="stream-now-btn" (click)="streamNow()"><img src="{{img_path}}stream-icon.svg" alt=""> <span
            [innerHTML]="'stream.detail.submitBtn' | translate">Proceed to live</span><span
            [ngClass]="{'spinner-border': is_submitted}"></span></a>
</div>