import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { PageService } from '../apis/page/page.service';

@Component({
  selector: 'app-single',
  templateUrl: './single.component.html',
  styleUrls: ['./single.component.scss']
})
export class SingleComponent implements OnInit {
  slug: string = '';
  page_title: string | undefined;
  page_desc: string | undefined;
  page_details: any;
  constructor(private router: Router, private activeRoute: ActivatedRoute, private page_api: PageService) {
    this.router.events.subscribe((event: any) => {
      if (event instanceof NavigationEnd) {
        this.slug = event.url.split("/").slice(-1)[0];
        this.page_api.getSinglePage(this.slug).subscribe(data => {
          this.page_details = data;
          this.page_details = this.page_details[0];
          this.page_title = this.page_details.title.rendered;
          this.page_desc = this.page_details.content.rendered;
        });
      }

    });

  }

  ngOnInit(): void {
  }

}
