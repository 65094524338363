<div class="scroll-to-top d-none d-sm-block" [ngClass]="{'show-scrollTop': windowScrolled}">
    <button type="button" class="scroll-btn active" data-toggle="button" aria-pressed="true" (click)="scrollToTop()">
        <img src="{{img_path}}arrow_scroll_top.svg" alt="">
    </button>
</div>

<div class="scroll-to-top d-block d-sm-none show-scrollTop-dummy">
    <button type="button" class="scroll-btn active" data-toggle="button" aria-pressed="true">
        <img src="{{img_path}}arrow_scroll_top.svg" alt="">
    </button>
</div>