<button type="button" *ngIf="is_popup" (click)="close()" class="close" aria-label="Close">
    <span aria-hidden="true">&times;</span>
</button>
<div class="new-stream-section container pt-5">
    <p *ngIf="route === '/hosting/new'" [innerHTML]="'stream.form.subtitle' | translate">Create your event and streams</p>
    <div class="new-stream-card row">
        <div class="col-sm-6 p-0">
            <app-streaming-form *ngIf="route === '/hosting/new'"></app-streaming-form>
            <app-streaming-detail *ngIf="route !== '/hosting/new'" [stream_id]="stream_id"></app-streaming-detail>
        </div>
        <div class="form-description col-sm-6 p-0">
            <div class="form-description-inner">
                <h2 [innerHTML]="'stream.description' | translate">Connect with fans and <br> see who's listening</h2>
            </div>
        </div>
    </div>
</div>