import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { DataStoreService } from '../apis/data-storage/data-store.service';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss']
})
export class SignupComponent implements OnInit {
  @ViewChild('currSlide') currSlide: any;
  @Input() redirectTo: string = '/';
  slide: string = "";
  img_path = '';
  isSlider: boolean = true;
  constructor(private router: ActivatedRoute, private routing: Router, private data_store_api: DataStoreService) {
    this.data_store_api.toggleLoader(false);
   }

  nextSlide(slide: any, carousel: any) {
    if (this.isSlider) {
      carousel.select(slide, carousel.NgbSlideEventSource);
    }
  }

  updateStatus(status: boolean) {
    this.isSlider = status;
  }

  ngOnInit() {
    if (this.isSlider) {
      this.data_store_api.toggleLoginView('login');
    }
    this.data_store_api.currentBaseUrl.subscribe((url: string) => this.img_path = url + "assets/img/icons/signup/");
    this.slide = this.router.snapshot.firstChild?.data?.view;
    this.routing.events.subscribe((event: any) => {
      if (event instanceof NavigationEnd) {
        this.slide = this.router.snapshot.firstChild?.data.view;
        this.nextSlide(this.slide, this.currSlide);
      }
    });
    if (localStorage.getItem('sessionUserToken')) {
      this.routing.navigate([this.redirectTo]);
    }

  }

}
