import { Component, OnInit, Input } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-confirm-window',
  templateUrl: './confirm-window.component.html',
  styleUrls: ['./confirm-window.component.scss']
})
export class ConfirmWindowComponent implements OnInit {
  @Input() is_form = false;
  @Input() message: any = 'Are you sure want to continue';
  @Input() classList = '';
  @Input() formData: any = [];
  @Input() confirm = 'OK';
  @Input() cancel = 'Cancel';
  @Input() confirmClasses = '';

  get_app = new FormGroup({});
  constructor(private modalRef: NgbActiveModal) { }
  confirmModal(response: any) {
    console.log(response);
    
    if (this.is_form) {
      this.modalRef.dismiss(response);
    } else {
      this.modalRef.dismiss("confirmed");
    }
  }
  cancelModal() {
    this.modalRef.close("cancelled");
  }
  ngOnInit(): void {
    if (this.formData.length > 0) {
      let formItems: any = {};
      this.formData.forEach((field: any) => {
        if (field.required) {
          if (field?.validators.length > 0) {
            formItems[field.name] = new FormControl(field.value, field.validators);
          } else {
            formItems[field.name] = new FormControl(field.value, [Validators.required]);
          }
        } else {
          formItems[field.name] = new FormControl(field.value);
        }
      });
      this.get_app = new FormGroup(formItems);

    }
  }

}
