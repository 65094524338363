import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})

export class StreamService {
  base_url = environment.urls.api_url;
  base_azura_url = environment.urls.azuracast_url;
  top_streamers_path = "top-streamers"; // params: count (number)
  auth_token = '';
  constructor(private http: HttpClient, private route: ActivatedRoute) {
    this.auth_token = JSON.stringify(localStorage.getItem('sessionUserToken'));
  }

  getTimeZones() {
    return this.http.get(this.base_url + "timezones");
  }

  getAllCategories() {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: JSON.parse(this.auth_token)
      })
    };
    return this.http.get(this.base_url + 'stream-categories', httpOptions);
  }

  createStream(request: Object) {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: JSON.parse(this.auth_token)
      })
    };
    return this.http.post(this.base_url + "create-stream", request, httpOptions);

  }

  handleStream(options: string) {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: JSON.parse(this.auth_token)
      })
    };
    return this.http.get(this.base_url + options, httpOptions);

  }

  displayCreatedStream(id: any) {
    return this.http.get(this.base_url + "stream?id=" + id);
  }

  listStreams(url: string) {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: JSON.parse(this.auth_token)
      })
    };
    return this.http.get(this.base_url + url, httpOptions);
  }

  updateStreams(request: Object) {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: JSON.parse(this.auth_token)
      })
    };
    return this.http.post(this.base_url + "update-stream", request, httpOptions);

  }

  removeStream(options: Object) {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: JSON.parse(this.auth_token)
      })
    };
    return this.http.post(this.base_url + "delete-stream", options, httpOptions);

  }

  refreshStreamData(url: string) {
    return this.http.get(url);
  }

  liveStreamers(count: number) {
    return this.http.get(this.base_url + "live-streams?count=" + count);
  }

  addClap(request: Object) {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: JSON.parse(this.auth_token)
      })
    };
    return this.http.post(this.base_url + "set_claps", request, httpOptions);
  }

  getClaps(id: number) {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: JSON.parse(this.auth_token)
      })
    };
    return this.http.get(this.base_url + "get_claps?post_id=" + id);
  }

  followStreamer(follow: string, request: Object) {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: JSON.parse(this.auth_token)
      })
    };
    return this.http.post(this.base_url + follow, request, httpOptions);
  }

  checkFollow(id: number) {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: JSON.parse(this.auth_token)
      })
    };
    return this.http.get(this.base_url + "follow?host_user=" + id, httpOptions);
  }

  syncStreamData(stream_id: string) {
    return this.http.get(this.base_azura_url + "api/nowplaying_static/" + stream_id + '.json');
  }

  /**
   * 
   * @param request {post_id: xxx, passcode: xxxx}
   * @returns 
   */
  privateListeningValidate(request: Object) {
    return this.http.post(this.base_url + 'validate_passcode', request);
  }
}
