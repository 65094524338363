import { Component, OnInit } from '@angular/core';
import { MenusService } from 'src/app/apis/menus/menus.service';

@Component({
  selector: 'app-footerlinks',
  templateUrl: './footerlinks.component.html',
  styleUrls: ['./footerlinks.component.scss']
})
export class FooterlinksComponent implements OnInit {
  menu_option_list: any;
  constructor(private menu_api: MenusService) { }


  ngOnInit(): void {
    this.menu_api.currentMenu.subscribe((list: any) => {
      if (list) {
        list = JSON.parse(list);
        this.menu_option_list = list.menu['footer-4'].items;
      }
    });
  }

}
