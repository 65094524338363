import { Component, OnInit } from '@angular/core';
import { MenusService } from '../apis/menus/menus.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  footerLinks: boolean = false;
  menu_arr: any;
  menu_list: any;
  constructor(private menu_api: MenusService) {


  }

  getMenus() {
    this.menu_api.getFooterMenus().subscribe((list: any) => {
      let menu_array = {
        date: Date.now(),
        menu: list
      }
      localStorage.setItem('vocoloopMenus', JSON.stringify(menu_array));
      this.menu_api.updateMenus(JSON.stringify(menu_array));
    });
  }

  date_diff_indays(date1: any, date2: any) {
    let dt1 = new Date(date1);
    let dt2 = new Date(date2);
    return Math.floor((Date.UTC(dt2.getFullYear(), dt2.getMonth(), dt2.getDate()) - Date.UTC(dt1.getFullYear(), dt1.getMonth(), dt1.getDate())) / (1000 * 60 * 60 * 24));
  }


  ngOnInit(): void {
    this.menu_api.currentMenu.subscribe((list: any) => {
      if (list?.length > 0) {
        let menu_arr: any = JSON.parse(list).menu;
        menu_arr = Object.values(menu_arr);
        menu_arr.filter((_menu: any) => _menu.items.map((item: any) => {
          if (item?.url) {
            if (item?.url.startsWith("https://vocoloop.com/live/")) {
              return this.getMenus();
            }
          }
        }));
        if (this.date_diff_indays(Date.now(), list?.date) < 10) {
          return this.getMenus();
        }
      } else {
        return this.getMenus();
      }
    });
  }

}
