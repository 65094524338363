<!-- form mobile screen -->
<div class="custom-tab-header d-flex d-sm-none">
    <div class="button" [ngbTooltip]="ngbtProfile" (click)="navigateTo('dashboard#profile')"
        [ngClass]="{'active': (activated_tab === 'profile')}">
        <div class="img-responsive">
            <img *ngIf="activated_tab !== 'profile'" src="{{img_path}}/profile.svg" alt="">
            <img *ngIf="activated_tab === 'profile'" src="{{img_path}}/profile-white.svg" alt="">
        </div>
    </div>

    <div class="button" [ngbTooltip]="ngbtScheduled" (click)="navigateTo('dashboard#scheduled')"
        [ngClass]="{'active': (activated_tab === 'scheduled')}">
        <div class="img-responsive">
            <img *ngIf="activated_tab !== 'scheduled'" src="{{img_path}}/scheduled.svg" alt="">
            <img *ngIf="activated_tab === 'scheduled'" src="{{img_path}}/scheduled-white.svg" alt="">
        </div>
    </div>
    <div class="button" [ngbTooltip]="ngbtCompleted" (click)="navigateTo('dashboard#completed')"
        [ngClass]="{'active': (activated_tab === 'completed')}">
        <div class="img-responsive">
            <img *ngIf="activated_tab !== 'completed'" src="{{img_path}}/completed.svg" alt="">
            <img *ngIf="activated_tab === 'completed'" src="{{img_path}}/completed-white.svg" alt="">
        </div>
    </div>
    <div class="button" [ngbTooltip]="ngbtSettings" (click)="navigateTo('dashboard#settings')"
        [ngClass]="{'active': (activated_tab === 'settings')}">
        <div class="img-responsive">
            <img *ngIf="activated_tab !== 'settings'" src="{{img_path}}/settings.svg" alt="">
            <img *ngIf="activated_tab === 'settings'" src="{{img_path}}/settings-white.svg" alt="">
        </div>
    </div>
</div>
<div class="d-block d-sm-none tab-section-responsive">

    <app-profile [user_details]="user_details" *ngIf="(activated_tab === 'profile')"></app-profile>
    <app-scheduled [user_details]="user_base_details" *ngIf="(activated_tab === 'scheduled')"></app-scheduled>
    <app-completed [user_details]="user_base_details" *ngIf="(activated_tab === 'completed')"></app-completed>
    <app-settings [user_details]="user_base_details" [user_timezone]="user_details.user_timezone"
        *ngIf="(activated_tab === 'settings')">
    </app-settings>
</div>
<div class="tab-section">
    <tabset type="fill" class="d-none d-sm-flex">
        <!-- <tabset type="fill"> -->
        <tab [active]="activated_tab === 'profile'" class="col h-100">
            <ng-template tabHeading [ngbTooltip]="ngbtProfile">
                <div class="img-container">
                    <img src="{{img_path}}/profile.svg" alt="">
                </div>
                <span class="text-label text-capitalize">Profile</span>
            </ng-template>
            <app-profile class="content-container position-unset" [user_details]="user_details"></app-profile>
        </tab>

        <tab [active]="activated_tab === 'scheduled'" class="col h-100">
            <ng-template tabHeading [ngbTooltip]="ngbtScheduled">
                <div class="img-container">
                    <img src="{{img_path}}/scheduled.svg" alt="">
                </div>
                <span class="text-label text-capitalize">Scheduled streams</span>
            </ng-template>
            <app-scheduled class="content-container" [user_details]="user_base_details"></app-scheduled>
        </tab>

        <tab [active]="activated_tab === 'completed'" class="col h-100">
            <ng-template tabHeading [ngbTooltip]="ngbtCompleted">
                <div class="img-container">
                    <img src="{{img_path}}/completed.svg" alt="">
                </div>
                <span class="text-label text-capitalize">Completed streams</span>
            </ng-template>
            <app-completed class="content-container" [user_details]="user_base_details"></app-completed>
        </tab>

        <tab [active]="activated_tab === 'settings'" class="col h-100">
            <ng-template tabHeading class="col" [ngbTooltip]="ngbtSettings" placement="right">
                <div class="img-container">
                    <img src="{{img_path}}/settings.svg" alt="">
                </div>
                <span class="text-label text-capitalize">Settings</span>
            </ng-template>
            <app-settings class="content-container" [user_details]="user_base_details"
                [user_timezone]="user_details.user_timezone">
            </app-settings>
        </tab>

    </tabset>
</div>

<!-- tab titles tooltip list start here -->

<ng-template #ngbtProfile>
    <p>Edit / view profile details</p>
</ng-template>

<ng-template #ngbtScheduled>
    <p>Scheduled streams</p>
</ng-template>

<ng-template #ngbtCompleted>
    <p>Completed streams</p>
</ng-template>

<ng-template #ngbtSettings>
    <p>Settings</p>
</ng-template>

<!-- tab titles tooltip list ends here -->