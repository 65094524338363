import { Component, OnInit } from '@angular/core';
import { DataStoreService } from '../apis/data-storage/data-store.service';

@Component({
  selector: 'app-logo',
  templateUrl: './logo.component.html',
  styleUrls: ['./logo.component.scss']
})
export class LogoComponent implements OnInit {
  img_path = "/assets/img/logo/svg/text-logo.svg";

  constructor(private data_store_api: DataStoreService) { }

  ngOnInit(): void {
    this.img_path = location.origin + this.img_path;
  }

}
