import { Component, EventEmitter, Input, AfterViewInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, FormControl } from '@angular/forms';
import { SignupService } from 'src/app/apis/signup/signup.service';
import { DataStoreService } from 'src/app/apis/data-storage/data-store.service';
import { Validators } from '@angular/forms';
import { ToastService } from 'src/app/apis/toast.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements AfterViewInit {
  @Output() changeSlide = new EventEmitter<string>();
  @Output() isSlider = new EventEmitter<boolean>(true);
  @Input() is_signup: boolean = true;

  loginForm = new FormGroup({
    username: new FormControl('', [Validators.required, Validators.email]),
    password: new FormControl('', [Validators.required]),
  });

  validateUserForm = new FormGroup({
    user_email: new FormControl('demo.vocoloop.com', [Validators.required, Validators.email]),
    code: new FormControl('', [Validators.required, Validators.pattern('[0-9]*')]),
  });

  forgotForm = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.email])
  });

  resetPassForm = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.email]),
    code: new FormControl('', [Validators.required]),
    new_password: new FormControl('', [Validators.required])
  });

  token: any = '';
  user_details: any;
  is_form_invalid: boolean = false;
  toast_opt = { classname: 'toast-success', header: " " };
  img_path = '';
  is_submitted: boolean = false;
  resend_link = 30;
  resend_link_status: boolean = true;
  is_user_validated = true;
  user_data: any = '';
  view: string = 'view';
  verification_code_err = '';
  constructor(
    private route: Router,
    private signup_api: SignupService,
    private toast: ToastService,
    private data_store_api: DataStoreService,
    private modal: NgbModal,

  ) { 
    
    this.data_store_api.currentBaseUrl.subscribe((url: string) => this.img_path = url + "assets/img/icons/signup/");
    this.data_store_api.currentUserToken.subscribe((token: any) => this.token = token);
    this.data_store_api.currentLoginView.subscribe((view: any) => this.view = view);
  }

  slideTo(slide: string) {
    this.changeSlide.emit(slide);
  }
  enableSlider(status: boolean = true) {
    this.isSlider.emit(status);
  }

  loginFormSubmit(loginForm: FormGroup) {
    this.is_form_invalid = (loginForm.invalid || loginForm.untouched) ? true : false;
    if (!this.is_form_invalid) {
      this.is_submitted = true;
      this.signup_api.userLoginCheck(loginForm.value).subscribe((result: any) => {
        this.data_store_api.updateUserToken(result.data.jwt);
        if (this.token !== '') {
          this.signup_api.userVerifyCheck(loginForm.value.username, this.token).subscribe((response: any) => {
            if (response.status) {
              if (result.success === true) {
                return this.userLoggedIn();
              } else { console.log(result); }
            } else {
              this.user_data = { email: loginForm.value.username, token: this.token };
              this.is_user_validated = false;
              this.validateUserForm.patchValue({ user_email: this.user_data.email });
            }
          }, (err) => {
            console.log(err);
          });

        } else {
          this.toast_opt.classname = 'toast-error';
          this.toast_opt.header = '';
          this.toast.show('token is missing', this.toast_opt);
        }
      }, (error) => {
        this.toast_opt.classname = 'toast-error';
        this.toast_opt.header = error?.status;
        this.toast.show(error?.error?.data?.message, this.toast_opt);
        this.is_submitted = false;
        console.log(error);
      });
    }
  }

  verifyUser(formData: FormGroup) {
    this.verification_code_err = '';
    this.is_submitted = true;
    this.signup_api.userVerifyValidate(formData.value).subscribe((result: any) => {
      if (result.status === "success") {
        return this.userLoggedIn();
      }
      console.log(result);
      this.verification_code_err = (result.status === false) ? (result?.message) : '';
    }, (err: any) => {
      this.is_submitted = false;
      this.toast_opt.classname = 'toast-error';
      this.toast_opt.header = err?.status;
      this.toast.show(err?.error?.data?.message, this.toast_opt);
      console.log(err);
    }, () => {
      this.is_submitted = false;
      console.log(this.verification_code_err);

    });
  }

  resendVerificationCode(email: string = '', token: string = '') {
    if (this.resend_link_status) {
      this.resend_link_status = false;
      this.resend_link = 12;
      let interval = setInterval(() => {
        this.resend_link--;
        if (this.resend_link === 0) {
          clearInterval(interval);
          this.resend_link_status = true;
        }
      }, 1000);
      if (email !== '' && token !== '') {
        this.signup_api.resendUserVerifyCode(email, token).subscribe((result: any) => {
          this.toast_opt.header = '';
          this.toast.show(result.message, this.toast_opt);
        }, (err) => {
          console.log(err);
          this.is_submitted = false;
        }, () => {
          this.is_submitted = false;

        });
      } else {
        this.toast_opt.classname = 'toast-error';
        this.toast_opt.header = 'fields invalid';
        this.toast.show('email or token is missing', this.toast_opt);
        this.is_submitted = false;
      }
    }
  }

  forgotPassword() {
    this.enableSlider(false);
    this.data_store_api.toggleLoginView('forgot');
  }

  resetPassword(formData: FormGroup) {
    this.is_submitted = true;
    this.signup_api.forgot(formData.value).subscribe((result: any) => {
      this.resetPassForm.patchValue({ email: formData.value.email });
      this.data_store_api.toggleLoginView('reset');
      this.toast_opt.header = 'Success';
      this.toast_opt.classname = 'toast-success';
      this.toast.show(result.message, this.toast_opt);
    }, (error: any) => {
      this.is_submitted = false;
      this.toast_opt.header = 'Failed';
      this.toast_opt.classname = 'toast-error';
      this.toast.show(error.error.data.message, this.toast_opt);
    }, () => this.is_submitted = false);
  }

  updatePassword(formData: FormGroup) {
    this.is_submitted = true;
    this.signup_api.resetPassword(formData.value).subscribe((result: any) => {
      this.toast_opt.header = 'Success';
      this.toast_opt.classname = 'toast-success';
      this.toast.show(result.message, this.toast_opt);
      this.enableSlider(true);
      this.data_store_api.toggleLoginView('login');
    }, (error: any) => {
      this.is_submitted = false;
      this.toast_opt.header = 'Failed';
      this.toast_opt.classname = 'toast-error';
      this.toast.show(error.error.data.message, this.toast_opt);
    }, () => this.is_submitted = false);
  }

  userLoggedIn() {
    this.signup_api.getUser(this.token).subscribe((details: any) => {
      localStorage.setItem('sessionUserToken', this.token);
      localStorage.setItem('user_details', JSON.stringify(details.user_data));
      this.data_store_api.updateUserDetails(JSON.stringify(details.user_data));
    }, (error) => {
      this.toast_opt.classname = 'toast-error';
      this.toast_opt.header = error?.status;
      this.toast.show(error?.error?.data?.message, this.toast_opt);
      this.is_submitted = false;
    }, () => {
      location.reload();
    });
  }
  ngAfterViewInit(): void {
  }

}
