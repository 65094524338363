<app-header></app-header>
<div class="banner-section">
    <app-banner></app-banner>
</div>

<!-- <div class="popular-streams section-1 position-relative">
    <app-section1></app-section1>
</div> -->

<div *ngIf="!is_logged_in" class="section-2">
    <app-section2></app-section2>
</div>

<div *ngIf="!is_logged_in" class="section-3">
    <app-section3></app-section3>
</div>


<div *ngIf="!is_logged_in" class="app-download-section">
    <app-app-download></app-app-download>
</div>
<app-scroll-top *ngIf="!is_mobile"></app-scroll-top>
<app-footer></app-footer>
