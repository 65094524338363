import { Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { ChatService } from 'src/app/apis/firebase/chat.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { DataStoreService } from 'src/app/apis/data-storage/data-store.service';
import { ActivatedRoute } from '@angular/router';
import { AngularFireAuth } from '@angular/fire/auth';

@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.scss']
})
export class ChatComponent implements OnInit {

  @ViewChild('scrollMe') private myScrollContainer: ElementRef | undefined;
  @ViewChild('newItem') private newHeight: ElementRef | undefined;

  chattingForm = new FormGroup({
    id: new FormControl(1),
    user_id: new FormControl(''),
    user_name: new FormControl(''),
    class_list: new FormControl(''),
    user_profile: new FormControl(''),
    message: new FormControl('', Validators.required),
    stream_id: new FormControl('notavailable'),
    created_at: new FormControl(),
    updated_at: new FormControl(new Date().toJSON("dd/MM/yyyy HH:mm")),
  });
  user_details: any;
  chat_data: any;
  chat_count: number = 0;
  stream_id = this.route.snapshot.paramMap.get('stream-id');
  is_loggedin = false;
  showEmojiPicker: boolean = false;
  img_path = '';
  created_at = new Date().toJSON("dd/MM/yyyy HH:mm");
  chat_note: string = `<div class="message-note">Welcome to live chat!<p> Remember to guard your privacy and follow our community guidelines. <a target="_blank" href="https://vocoloop.com/app/community-guidelines">Learn more</a></p></span>`;
  sets = [
    "google",
    "twitter",
    "facebook",
    "apple",
  ];
  constructor(private fire_api: ChatService, private data_store_api: DataStoreService, private route: ActivatedRoute, private router: ActivatedRoute, private fireAuth: AngularFireAuth) {

  }
  @HostListener('document:keydown.escape', ['$event']) escapeFromEmoji() {
    this.showEmojiPicker = false;
  }
  AllChatList() {
    this.fire_api.getChatList(this.stream_id).snapshotChanges().subscribe((data: any) => {
      this.chattingForm.controls.id.patchValue(data.length + 1);
      this.chat_data = data.map((c: any) => c.payload.val());
      this.chat_count = this.chat_data.length;
      if (this.chat_data.length) {
        setTimeout(() => {
          this.scrollToBottom();
        }, 1);
      }
    })
  }

  addEmoji(emoji: any) {
    this.chattingForm.controls.message.patchValue(this.chattingForm.value.message + emoji.emoji.native);
  }
  sendMsg() {
    this.showEmojiPicker = false;
    if (this.chat_count === 0) {
      this.created_at = new Date().toJSON("dd/MM/yyyy HH:mm");
    }
    this.chattingForm.patchValue({
      created_at: this.created_at,
      updated_at: new Date().toJSON("dd/MM/yyyy HH:mm")
    });

    this.fire_api.newChat(this.chattingForm.value, this.stream_id);
    this.chattingForm.controls.message.patchValue('');
    setTimeout(() => {
      this.scrollToBottom();
    }, 1);
  }

  ClearChat() {
    this.fire_api.clearChat(this.stream_id);
  }

  scrollToBottom(): void {
    try {
      if (this.myScrollContainer !== undefined && this.newHeight !== undefined) {
        this.myScrollContainer.nativeElement.scrollTop = this.myScrollContainer.nativeElement.scrollHeight + this.newHeight.nativeElement.scrollHeight;
      }
    } catch (err) { }
  }


  ngOnInit(): void {
    this.data_store_api.currentBaseUrl.subscribe((url: string) => this.img_path = url + "assets/img/icons/");
    this.is_loggedin = (localStorage.getItem('sessionUserToken') !== null) ? true : false;
    if (this.is_loggedin) {
      this.fire_api.loginFirebase(JSON.stringify(localStorage.getItem('sessionUserToken'))).subscribe((data: any) => {
        this.fireAuth.signInWithCustomToken(data.data.token).then((userCredential) => {
          // console.log(userCredential.user);
        })
          .catch((error) => {
            console.log(error);
          });
      }, (error: any) => console.log(error));
      this.data_store_api.currentUserDetails.subscribe((details: any) => {
        this.user_details = JSON.parse(details);
        this.chattingForm.patchValue({
          user_id: this.user_details.ID,
          user_name: this.user_details.display_name,
          user_profile: this.user_details.user_avatar
        });
      });
    }
    this.AllChatList();
  }
}
