import { Injectable } from '@angular/core';
import { AngularFireDatabase, AngularFireList } from '@angular/fire/database';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Chat } from 'src/app/models/chat.model';
import { environment as cofig} from 'src/environments/environment';



@Injectable({
  providedIn: 'root'
})
export class ChatService {
  base_url = cofig.urls.api_url;

  constructor(private db: AngularFireDatabase, private http: HttpClient) {
    // if (location.hostname === 'localhost') {
    //   this.base_url = "http://localhost/livo-wp/wp-json/livo/v1/";
    // }
  }

  newChat(request: Chat, stream_id: any) {
    const chat_list = this.db.list(stream_id + '/chat');
    chat_list.push(request);
  }

  getChatList(stream_id: any) {
    return this.db.list(stream_id + '/chat');
  }

  deleteChat(key: string, stream_id: any) {
    const chat_list = this.db.list(stream_id + '/chat');
    return chat_list.remove(key);
  }
  clearChat(stream_id: any) {
    const chat_list = this.db.list(stream_id);
    return chat_list.remove();
  }

  loginFirebase(token: string) {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: JSON.parse(token)
      })
    };
    return this.http.get(this.base_url + "sg-create-firebase-token", httpOptions);
  }

  updateClap(count: number, stream_id: string) {
    let clap = this.db.list(stream_id + '/clap_count')
    clap.remove();
    return clap.push(count);
  }

  syncClap(stream_id: any) {
    return this.db.list(stream_id + '/clap_count');
  }

  updateStreamStatus(status: string, stream_id: string) {
    let clap = this.db.list(stream_id + '/status')
    clap.remove();
    return clap.push(status);
  }

  syncStreamStatus(stream_id: any) {
    return this.db.list(stream_id + '/status');
  }
}
