<app-header></app-header>
<div class="column body-light col-auto">
    <div class="row pb-5 pt-4">
        <div class="container">
            <div class="col-12">
                <h2 class="pt-3 pb-3 ">{{ page_title }}</h2>
            </div>
            <div [innerHTML]="page_desc" class="col-12 content-block"></div>
        </div>
    </div>
</div>
<app-scroll-top></app-scroll-top>
<app-footer></app-footer>