import { Component, Input, OnInit } from '@angular/core';
import { DataStoreService } from 'src/app/apis/data-storage/data-store.service';
import { SignupService } from 'src/app/apis/signup/signup.service';
import { ToastService } from 'src/app/apis/toast.service';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit {
  @Input() user_details = {
    display_name: '',
    user_email: ''
  };
  @Input() user_timezone = '';
  timezones: any;
  user_token: string = '';
  toast_opt = { classname: 'toast-success', header: ' ' };
  active_lang = "en";
  constructor(
    private data_store_api: DataStoreService,
    private signup_api: SignupService,
    private toast: ToastService
  ) { }

  updateTimezone(event: any) {
    let timezone = event.target.value;
    timezone = { user_timezone: timezone };
    this.signup_api.updateUser(timezone, this.user_token).subscribe((usr_data: any) => {
      if (usr_data.status === "success") {
        this.toast_opt.header = usr_data.status;
        localStorage.setItem('user_details', JSON.stringify(usr_data.updated_user_data));
        this.data_store_api.updateUserDetails(JSON.stringify(usr_data.updated_user_data));
        if (!usr_data.message) {
          usr_data.message = "Timezone updated!";
        }
      } else {
        if (!usr_data.message) {
          usr_data.message = "failed to update Timezone!";
        }
        this.toast_opt.classname = 'toast-error';

      }
      window.scroll(0, 0);
      this.toast.show(usr_data?.message, this.toast_opt);

    });
  }
  resetPassword(email: string) {
    // let options = { email: email, rest_route: "jwt-login/v1/user/reset_password" };
    let options = 'email=' + email +'&rest_route=jwt-login/v1/user/reset_password';
    this.signup_api.updateUserPassword(email, this.user_token).subscribe((result: any) => {
      this.toast_opt.header = result?.status;
      this.toast.show(result?.message, this.toast_opt);
    }, (error: any) => {
      this.toast_opt.classname = 'toast-error';
      if (error?.statusText) {
        this.toast_opt.header = error?.statusText;
      } else {
        this.toast_opt.header = error?.status;
      }
      this.toast.show(error?.message, this.toast_opt);
      console.log(error);
    });

  }

  updateLang(lang: string) {
    if (this.active_lang !== lang) {
      this.data_store_api.updateContentLang(lang);
      localStorage.setItem('vlContentLang', lang);
      this.toast.show("content language changed.", { classname: "toast-success" });
    }
  }
  ngOnInit(): void {
    this.data_store_api.currentContentLang.subscribe(lang => {
      if (lang !== null) {
        this.active_lang = lang;
      }
    })
    this.data_store_api.currentTimezones.subscribe(data => {
      this.timezones = data;
    });
    let usr_token: any = '';
    this.data_store_api.currentUserToken.subscribe(token => {
      usr_token = token;
      this.user_token = usr_token;
    });
  }

}
