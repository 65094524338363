<div class="d-none d-sm-block">
    <ngb-carousel *ngIf="images" [showNavigationArrows]="false">
        <ng-template ngbSlide *ngFor="let image of images">
            <div class="picsum-img-wrapper">
                <img [src]="image" alt="Random first slide">
            </div>
        </ng-template>
    </ngb-carousel>
</div>
<div class="carousel-caption text-center text-sm-left col col-lg-6 col-sm-10 col-12">
    <div class="container">
        <h4 class="banner-pretitle" [innerHTML]="'home.banner.subtitle' | translate">Let's start a live audio</h4>
        <h1 class="banner-title" [innerHTML]="'home.banner.maintitle' | translate">broadcast Live audio free</h1>
        <h3 class="banner-subtitle" [innerHTML]="'home.banner.desc' | translate">Create live audio broadcasting and
            share it with audiences of any size from the browser.</h3>
        <div class="d-block d-sm-flex mt-sm-4 stream-option">
            <div class="input-listening-stream">
                <input type="text" [formControl]="stream_id" name=""
                    [ngbTooltip]="'home.banner.form.placeholder' | translate" (paste)="paste_link($event)"
                    (keyup.enter)="type_link()" [placeholder]="'home.banner.form.placeholder' | translate" id="">
                <button class="input-action-btn" (click)="type_link()"><img
                        src="../../../assets/img/icons/arrow_down.svg" alt=""></button>
            </div>
            <span class="d-block d-sm-inline mt-3 mb-3 mt-sm-0 mb-sm-0" [innerHTML]="'otherwise' | translate">Or</span>
            <a [routerLink]="['/hosting/new']" class="direct-stream-action" [innerHTML]="'goLiveBtn' | translate">Go
                live</a>
        </div>
    </div>
</div>