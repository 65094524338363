import { Component, Input, OnInit } from '@angular/core';
import { MenusService } from 'src/app/apis/menus/menus.service';
@Component({
  selector: 'app-menu-list',
  templateUrl: './menu-list.component.html',
  styleUrls: ['./menu-list.component.scss']
})
export class MenuListComponent implements OnInit {

  @Input() section: any = '';
  menu_title: any;
  menu_option_list: any;
  constructor(private menu_api: MenusService) { }

  ngOnInit(): void {
    this.menu_api.currentMenu.subscribe((list: any) => {
      if (list) {
        list = JSON.parse(list);
        this.menu_title = list.menu[this.section].name;
        this.menu_option_list = list.menu[this.section].items;
      }
    });
  }

}
