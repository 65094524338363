// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.


  let firebaseConfig = {
    apiKey: "AIzaSyBvXws33zAk-8c-OONwadUP6-oMc3bHyhg",
    authDomain: "prod-vocoloop.firebaseapp.com",
    databaseURL: "https://prod-vocoloop-default-rtdb.firebaseio.com",
    projectId: "prod-vocoloop",
    storageBucket: "prod-vocoloop.appspot.com",
    messagingSenderId: "286053594479",
    appId: "1:286053594479:web:691f5d33595e91a474249a",
    measurementId: "G-H5CEMCEFCB"
  }
let url_list = {
  'base_url': 'https://vocoloop.com/app/wp-json/',
  'api_url': 'https://vocoloop.com/app/wp-json/livo/v1/',
  'azuracast_url': 'https://broadcast.vocoloop.com/' // old: https://live.vocoloop.com/
}
export const environment = {
  production: false,
  firebase: firebaseConfig,
  urls: url_list
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
