import { Component, ViewChild, AfterViewInit } from '@angular/core';
import { DataStoreService } from 'src/app/apis/data-storage/data-store.service';
import { StreamService } from 'src/app/apis/stream.service';
import { PrivateStreamingUnknownUserComponent } from 'src/app/popups/private-streaming-unknown-user/private-streaming-unknown-user.component';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ChatService } from 'src/app/apis/firebase/chat.service';
import { ToastService } from 'src/app/apis/toast.service';
import { ConfirmWindowComponent } from 'src/app/popups/confirm-window/confirm-window.component';
import { PrivateListeningTokenComponent } from 'src/app/popups/private-listening-token/private-listening-token.component';
import { AuthenticationGuard } from 'src/app/authentication.guard';
import * as schema from "src/assets/schemas/schema.json";
import * as openGraph from "src/assets/schemas/open-graph.json";

declare const init_livestream: any;
declare const play_livestream: any;
declare const pause_livestream: any;
declare const vol_update_livestream: any;
declare var Wave: any;
@Component({
  selector: 'app-listening',
  templateUrl: './listening.component.html',
  styleUrls: ['./listening.component.scss']
})
export class ListeningComponent implements AfterViewInit {

  @ViewChild('wavebase') wavebase: any;
  @ViewChild('player') player: any;

  schemas = [schema, openGraph]

  stream_id: string = this.route.snapshot.paramMap.get('stream-id') || '';
  listeners_count: number = 0;
  clap_count: number = 0;

  station_url: string = "";
  vol_level: string = 'high'; // values are: mute, low, normal, high
  follow_text: string = "Follow";
  img_path: string = '';

  vol_control_panel: boolean = false; // values are: mute, low, normal, high
  chat_popup: boolean = false;
  is_chat_hide: boolean = true;
  is_private: boolean = false;
  is_online: boolean = false;
  is_playing: boolean = false;
  is_paused: boolean = false;
  is_live: boolean = true;
  is_loggedin: boolean = false;
  is_follow: boolean = false;
  is_clapped: boolean = false;

  stream: any;
  station_data: any;
  station_update_data: any;
  audio: any;
  elapsed_time: any = "00:00";
  elapsed_count: number = 0;
  sync_playing: any;
  readMore: boolean = true;
  timerIntval: any;
  toast_opt: any = {}
  modalRef: any;
  stream_status: string = "Waiting";

  schedule_count_start = new Date();
  schedule_count_end = new Date();
  schedule_count: any = "";
  constructor(
    private data_store_api: DataStoreService,
    private stream_api: StreamService,
    private fire_api: ChatService,
    private route: ActivatedRoute,
    private modalService: NgbModal,
    private router: Router,
    private toast: ToastService,
    private canActivate: AuthenticationGuard
  ) { }

  play() {
    if (this.station_url !== '') {
      this.is_playing = true;
      play_livestream(this.player.nativeElement);
      if (!this.is_paused) {
        this.sync_player();
      }
      this.is_paused = false;
    } else {
      alert("audio url is missing");
    }
  }

  async pause() {
    this.is_paused = true;
    this.is_playing = false;
    await pause_livestream(this.player.nativeElement);
  }

  control_vol(vol: number, new_level: string) {
    this.vol_level = new_level;
    // this.vol_control_panel = false;
    vol_update_livestream(this.player.nativeElement, vol);

  }

  sync_player() {
    this.timerIntval = setInterval(() => {
      this.elapsed_count = this.elapsed_count + 1;
      this.elapsed_time = this.sec_to_time_convert(this.elapsed_count);
    }, 1500);
    this.stream_api.refreshStreamData(this.stream.status_sync_nchan).subscribe((station_update_data: any) => {
      this.elapsed_count = station_update_data.now_playing.elapsed;
      this.elapsed_time = this.sec_to_time_convert(station_update_data.now_playing.elapsed);
    }, (err: any) => console.log(err));
  }

  sec_to_time_convert(time: number) {
    let hours: any = Math.floor(time / 3600);
    let minutes: any = Math.floor((time - (hours * 3600)) / 60);
    let seconds: any = time - (hours * 3600) - (minutes * 60);

    if (hours < 10) { hours = "0" + hours; }
    if (minutes < 10) { minutes = "0" + minutes; }
    if (seconds < 10) { seconds = "0" + seconds; }

    return minutes + ":" + seconds;
  }

  getTimeRemaining(endtime: any) {
    let total = Date.parse(endtime) - Date.parse(new Date().toISOString());
    let seconds = Math.floor((total / 1000) % 60);
    let minutes = Math.floor((total / 1000 / 60) % 60);
    let hours = Math.floor((total / (1000 * 60 * 60)) % 24);
    let days = Math.floor(total / (1000 * 60 * 60 * 24));

    // return { total, days, hours, minutes, seconds };
    return {
      total: total,
      value: days + ' Days ' + hours + ' Hours ' + minutes + ' Mins ' + seconds + ' Seconds'
    };
  }

  initializeClock(endtime: string) {
    const timeinterval = setInterval(() => {
      const remain_count = this.getTimeRemaining(endtime);
      if (remain_count.total <= 0) {
        clearInterval(timeinterval);
      }
      this.schedule_count = remain_count.value;
    }, 1000);
  }

  clap() {
    this.is_clapped = true;
    this.stream_api.addClap({ post_id: this.stream.ID }).subscribe((clap_data: any) => {
      this.clap_count = clap_data.claps;
      this.fire_api.updateClap(this.clap_count, this.stream_id);
      setTimeout(() => {
        this.is_clapped = false;
      }, 60000);
    });
  }
  follow_streamer(follow: boolean) {
    this.is_follow = !this.is_follow;

    this.stream_api.followStreamer(follow ? 'unfollow' : 'follow', { host_user: this.stream.post_author }).subscribe(data => {
      this.follow_text = this.is_follow ? 'Unfollow' : 'Follow';
    });
  }

  redirect_stream_details(url: string) {
    let modalRef = this.modalService.open(ConfirmWindowComponent, {
      ariaLabelledBy: 'modal-basic-title',
      size: 'md',
      centered: true,
      keyboard: false,
      windowClass: 'confirm-box',
      backdropClass: 'confirm-box-backdrop',
      backdrop: 'static'
    });
    modalRef.componentInstance.is_form = false;
    modalRef.componentInstance.message = "This event is " + this.stream_status;
    modalRef.componentInstance.cancel = "back to home";
    modalRef.componentInstance.confirm = (this.stream_status.toLowerCase() === 'completed') ? "Listen Podcast" : "view details";
    modalRef.result.then((cancel) => {
      this.router.navigate(['/']);

    }, (confirm) => {
      window.open(url, "_blank");

    });
  }

  stream_init(result: any = {}) {
    this.stream_status = result.stream_status;

    if (result?.stream_status === 'live') {

      this.fire_api.syncStreamStatus(this.stream_id).snapshotChanges().subscribe((statusData: any) => {
        statusData.map((c: any) => this.stream_status = c.payload.val());
      });
      this.data_store_api.currentUserToken.subscribe((token: any) => {
        this.is_loggedin = (token !== null) ? true : false;
        this.stream_api.displayCreatedStream(this.stream_id).subscribe(async (data: any) => {
          this.stream_status = data?.stream_status;
          if (data.status === "success") {
            this.stream = data;
            this.schemas = this.schemas.filter(shema => this.updateSchemaData(shema));

            if (data.stream_status !== "scheduled") {

              setInterval(() => {
                this.stream_api.syncStreamData(this.stream_id).subscribe((data: any) => {
                  this.listeners_count = data?.listeners?.current;
                  this.is_live = data?.live?.is_live;
                });
              }, (1000 * 60));
              this.is_private = data.visibility !== 'public';
              if (this.is_private && !this.is_loggedin) {
                this.modalRef = this.modalService.open(PrivateStreamingUnknownUserComponent,
                  { backdrop: 'static', size: 'xl', centered: true, windowClass: 'unknown-user-info-panel', keyboard: false });
                this.modalRef.componentInstance.redir_url = 'live/' + this.stream_id;
              } else {
                this.stream = data;
                this.is_chat_hide = (this.stream.hide_chat === 1) ? true : false;
                this.station_data = data.station_details;
                this.station_url = this.station_data?.station?.listen_url;
                await init_livestream();
                if (this.station_data?.now_playing) {
                  this.elapsed_count = this.station_data.now_playing?.elapsed;
                }
                this.stream_api.getClaps(this.stream.ID).subscribe((clap_data: any) => this.clap_count = clap_data.claps);
                if (this.is_loggedin) {
                  this.stream_api.checkFollow(this.stream.post_author).subscribe((follow_check_data: any) => {
                    this.is_follow = follow_check_data.following;
                    this.follow_text = this.is_follow ? 'Unfollow' : 'Follow';
                  });
                }
              }
            } else {
              this.initializeClock(data.live_date);
            }
          } else {
            this.toast_opt.header = data?.status;
            this.toast_opt.classname = "toast-error";
            this.toast.show(data?.message, this.toast_opt);
            this.router.navigate(['404']);
          }
        }, (error: any) => console.log(error), () => {
          this.data_store_api.toggleLoader(false);
        });
      });
    } else if (result.visibility === 'public') {
      this.data_store_api.toggleLoader(false);
      this.redirect_stream_details(result.guid);
    } else {
      this.data_store_api.toggleLoader(false);
      this.router.navigate(['/']);
      this.toast_opt.header = "Already completed..";
      this.toast_opt.classname = "toast-success";
      this.toast.show('Streaming already completed', this.toast_opt);

    }

  }

  updateSchemaData(data: any) {
    if (this.stream !== undefined) {
      Object.keys(data.default).forEach((key: any) => {
        switch (key) {
          case 'name': case 'og:title':
            data.default[key] = this.stream.stream_title;
            break;
          case 'description': case 'og:description':
            data.default[key] = this.stream.stream_desc;
            break;
          case 'url': case 'og:url':
            data.default[key] = this.stream.url;
            break;
          case '@type':
            if (data.default[key] === 'BroadcastEvent') {
              Object.keys(data.default['broadcastOfEvent']).forEach(event => {
                switch (event) {
                  case 'name':
                    data.default['broadcastOfEvent'][event] = this.stream.stream_title;

                    break;
                  case 'performer':
                    data.default['broadcastOfEvent'][event].name = this.stream.display_name;
                    break;
                  default:

                    if (event.toLowerCase().includes('date')) {
                      data.default['broadcastOfEvent'][event] = this.stream?.live_date;
                    }
                    break;
                }

              });
            }
            break;
          case 'og:audio':
            Object.keys(data.default[key]).forEach(event => {
              switch (event) {
                case 'title':
                  data.default[key][event] = this.stream.stream_title;
                  break;
                case 'url':
                  data.default[key].url = this.stream.url;
                  break;
                case 'artist':
                  data.default[key].artist = this.stream.display_name;
                  break;
                default:
                  if (event.toLowerCase().includes('date')) {
                    data.default[key] = this.stream?.live_date;
                  }
                  break;
              }
            });
            break;
          default:
            if (key.toLowerCase().includes('date')) {
              data.default[key] = this.stream?.live_date;
            }
            break;
        }
      });
    }
    return data;
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.data_store_api.currentBaseUrl.subscribe((url: string) => {
        if (url !== '') {
          this.img_path = url + "assets/img/icons/";
        }

      });

    }, 800);
    if (this.stream_id !== "completed") {
      this.stream_api.displayCreatedStream(this.stream_id).subscribe((result: any) => {
        if (result.visibility === 'private' && !(this.data_store_api.getCookie('voco_private_verified') === 'true')) {
          this.data_store_api.toggleLoader(false);
          if (this.canActivate.canActivate()) {
            console.log(result);

            let modalRef = this.modalService.open(
              PrivateListeningTokenComponent,
              {
                backdrop: 'static',
                size: 'sm',
                centered: true,
                windowClass:
                  'private-stream-verify-token',
                keyboard: false
              });
            modalRef.componentInstance.details = {
              title: "Please enter the 4-digit passcode",
              tooltip: "Please enter the 4-digit passcode to listen to the audio, which will be provided by the live event host.",
              btn_text: "Continue",
              post_id: result?.ID,
            }
            modalRef.result.then((cancel) => {
            }, (confirm) => {
              if (confirm.results.status === 'success') {
                document.cookie = "voco_private_verified=true;"
                this.toast_opt.header = "Listener verified";
                this.toast_opt.classname = "toast-success";
                this.toast.show(confirm?.results?.status, this.toast_opt);
                this.stream_init(result);
              }
            });
          }
        } else {
          this.stream_init(result);
        }
      });
    }
  }
}