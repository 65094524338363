import { Component, HostBinding, Input, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DataStoreService } from 'src/app/apis/data-storage/data-store.service';
import { ChatService } from 'src/app/apis/firebase/chat.service';
import { StreamService } from 'src/app/apis/stream.service';
import { ToastService } from 'src/app/apis/toast.service';
import { ConfirmWindowComponent } from 'src/app/popups/confirm-window/confirm-window.component';

@Component({
  selector: 'app-completed',
  templateUrl: './completed.component.html',
  styleUrls: ['./completed.component.scss']
})
export class CompletedComponent implements OnInit {
  @HostBinding('class.h-100') status: string = 'error';

  @Input() user_details = {
    display_name: '',
    user_email: '',
  };

  list: any;
  error: string = 'No streams completed';
  img_path = '';
  toast_opt = { classname: 'toast-success', header: ' ' };
  stream_detail_url = "https://vocoloop.com/app/stream/";

  constructor(
    private stream_api: StreamService,
    private modal: NgbModal,
    private toast: ToastService,
    private data_store_api: DataStoreService,
    private chat_api: ChatService,
    private fireAuth: AngularFireAuth
  ) { }

  removeStreaming(post_id: any, stream_id: string = '') {

    let modalRef = this.modal.open(ConfirmWindowComponent, {
      ariaLabelledBy: 'modal-basic-title',
      size: 'sm',
      centered: true,
      keyboard: false,
      windowClass: 'confirm-box',
      backdropClass: 'confirm-box-backdrop',
      backdrop: 'static'
    });
    modalRef.componentInstance.is_form = false;
    modalRef.componentInstance.message = "Do you really want to delete permanently?";
    modalRef.componentInstance.cancel = "Cancel";
    modalRef.componentInstance.confirm = "DELETE";
    modalRef.result.then((cancel) => {
    }, (confirm) => {
      this.data_store_api.toggleLoader(true);

      if (confirm === "confirmed") {
        if (stream_id !== '') {
          this.chat_api.clearChat(stream_id);
        }
        this.stream_api.removeStream({ post_id: post_id }).subscribe((result: any) => {
          this.toast_opt.header = result?.status;
          if (this.toast_opt.header === 'error') {
            this.toast_opt.classname = "toast-error";
            this.toast_opt.header = result?.status;
          }

          this.toast.show(result?.message, this.toast_opt);
        }, errors => {
          this.toast_opt.classname = "toast-error";
          this.toast_opt.header = errors?.status;
          this.data_store_api.toggleLoader(false);
          this.toast.show(errors?.message, this.toast_opt);
        }, () => {
          this.list = this.list.filter((item: any) => item.id !== post_id);
          this.data_store_api.toggleLoader(false);
          if (this.list.length == 0) {
            this.status = 'error';
          }
          if (location.href.includes("#completed")) {
          } else {
            location.href = location.href + "#completed";
          }
        });
      }
    });
  }

  copyUrl(message: string = "Success") {
    this.toast_opt.classname = "toast-success";
    this.toast.show(message, this.toast_opt);
  }

  redirect(stream_id: string = "") {
    if (stream_id !== '') {
      window.open("https://vocoloop.com/app/stream/" + stream_id);
    }
  }

  ngOnInit() {
    this.data_store_api.currentBaseUrl.subscribe((url: string) => this.img_path = url + "assets/img/icons/");
    this.stream_api.listStreams("completed-streams").subscribe((data: any) => {
      this.status = data?.status;
      console.log(data);
      if (data.status === 'error') {
        this.error = data.message;
      } else {

        if (data?.streams?.length > 0) {
          this.list = data?.streams;
        } else {
          this.list = [];

        }

      }
    }, (err: any) => console.log(err), () => {
      this.data_store_api.toggleLoader(false);
    });


    this.chat_api.loginFirebase(JSON.stringify(localStorage.getItem('sessionUserToken'))).subscribe((data: any) => {
      this.fireAuth.signInWithCustomToken(data.data.token).then((userCredential) => {
        // console.log(userCredential);
      })
        .catch((error) => {
          console.log(error);
        });
    });
  }

}
