<div class="modal-body {{ classList }}">
    <div class="modal-inner" [innerHTML]="message"></div>
    <div *ngIf="is_form" class="modal-inner">
        <form [formGroup]="get_app">
            <div class="input-field" *ngFor="let field of formData">
                <input [type]="field.type || 'text'" [placeholder]="field.placeholder || ''" [class]="field.classes"
                    [formControlName]="field.name || ''" [required]="field.required || false">
            </div>
            <div class="input-field">
                <button *ngIf="cancel !== ''" class="btn cancel-default-btn" [innerHTML]="cancel"
                    (click)="cancelModal()"></button>
                <input type="submit" *ngIf="confirm !== ''" [class]="confirmClasses"
                    class="btn confirm-default-btn bg-default" [ngClass]="{disabled: !get_app.valid}" [value]="confirm"
                    (click)="confirmModal(get_app.value)">
            </div>

        </form>
    </div>
</div>
<div *ngIf="!is_form" class="modal-footer">
    <button *ngIf="cancel !== ''" class="btn cancel-default-btn" [innerHTML]="cancel" (click)="cancelModal()"></button>
    <button *ngIf="confirm !== ''" class="btn confirm-default-btn bg-default" [innerHTML]="confirm"
        (click)="confirmModal('')"></button>
</div>