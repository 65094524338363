<!-- <app-header></app-header> -->
<div class="position-relative">
    <app-toast></app-toast>
</div>
<div class="signup-section">
    <div class="container">
        <div class="row mt-5">
            <div class="col-sm-6">
                <div class="logo">
                    <app-logo></app-logo>
                </div>
                <div class="description">
                    <h2>Welcome to Vocoloop</h2>
                </div>
            </div>
            <div class="col-sm-6">
                <div class="signup-panel">
                    <div class="form-icon">
                        <img src="{{img_path}}red_user.svg" alt="">
                    </div>
                    <div class="signup-panel-inner">
                        <div *ngIf="isSlider">
                            <ngb-carousel [interval]="0" [showNavigationArrows]="false" #currSlide [activeId]="slide">
                                <ng-template ngbSlide id="login">
                                    <app-login class="signup" [is_signup]="true" (isSlider)="updateStatus($event)" (changeSlide)="nextSlide($event, currSlide)"></app-login>
                                </ng-template>
                                <ng-template ngbSlide id="register">
                                    <app-register (isSlider)="updateStatus($event)" (changeSlide)="nextSlide($event, currSlide)"></app-register>
                                </ng-template>
                            </ngb-carousel>
                        </div>
                        <app-login class="signup" [is_signup]="true" *ngIf="!isSlider"></app-login>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- <div class="container mt-2">
    <app-copyright></app-copyright>
</div> -->