import { Component, HostBinding, Input, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DataStoreService } from 'src/app/apis/data-storage/data-store.service';
import { StreamService } from 'src/app/apis/stream.service';
import { ToastService } from 'src/app/apis/toast.service';
import { ConfirmWindowComponent } from 'src/app/popups/confirm-window/confirm-window.component';
import { StreamingCardComponent } from 'src/app/stream/hosting/new-streaming/streaming-card/streaming-card.component';

@Component({
  selector: 'app-scheduled',
  templateUrl: './scheduled.component.html',
  styleUrls: ['./scheduled.component.scss']
})
export class ScheduledComponent implements OnInit {
  @HostBinding('class.h-100') status: string = 'error';
  @Input() user_details = {
    display_name: '',
    user_email: '',
  };

  list: any = [];
  
  error: string = 'No streams Scheduled';
  closeResult = '';
  toast_opt = { classname: 'toast-success', header: ' ' };
  img_path = '';

  constructor(private service: StreamService, private modal: NgbModal, private toast: ToastService, private data_store_api: DataStoreService) { }

  open(stream_id: string) {
    let modal_ref = this.modal.open(StreamingCardComponent, {
      ariaLabelledBy: 'modal-basic-title', size: 'xl', centered: true, windowClass: 'dark-bg'
    });
    modal_ref.componentInstance.is_popup = true;
    modal_ref.componentInstance.stream_id = stream_id;
    modal_ref.result.then(() => { }, () => this.listScheduledStreams());
  }

  removeStreaming(el: any, post_id: any) {
    let modalRef = this.modal.open(ConfirmWindowComponent, {
      ariaLabelledBy: 'modal-basic-title',
      size: 'sm',
      centered: true,
      keyboard: false,
      windowClass: 'confirm-box',
      backdropClass: 'confirm-box-backdrop',
      backdrop: 'static'
    });
    modalRef.componentInstance.is_form = false;
    modalRef.componentInstance.message = "Are you sure you want to delete this?";
    modalRef.componentInstance.cancel = "Cancel";
    modalRef.componentInstance.confirm = "DELETE";
    modalRef.result.then(() => {
    }, (confirm) => {
      if (confirm === "confirmed") {
        this.data_store_api.toggleLoader(true);
        this.service.removeStream({ post_id: post_id }).subscribe((result: any) => {
          this.toast_opt.header = result?.status;
          this.toast.show(result?.message, this.toast_opt);
        }, errors => {
          this.toast_opt.classname = "toast-error";
          this.toast_opt.header = errors?.status;
          this.toast.show(errors?.message, this.toast_opt);
          this.data_store_api.toggleLoader(false);
          
        }, () => {
          this.list = this.list.filter((item: any) => item.post_id !== post_id);
          this.data_store_api.toggleLoader(false);
        });
      }
    });
  }

  listScheduledStreams() {
    this.service.listStreams("scheduled-streams").subscribe((data: any) => {
      this.status = data?.status;
      console.log(data);

      if (data.status === 'error') {
        this.error = data.message;
      } else {
        if (data?.streams?.length > 0) {
          this.list = data?.streams;
        } else {
          this.list = [];
        }
      }
    });

  }

  ngOnInit(): void {
    this.data_store_api.currentBaseUrl.subscribe((url: string) => this.img_path = url + "assets/img/icons/");
    this.listScheduledStreams();
  }

}
