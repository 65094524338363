<div class="container pt-5">
    <div class="row">
        <app-logo class="col-12 col-sm-2 text-center text-sm-center"></app-logo>
        <app-menu-list section="footer-1" class="col-4 col-sm-2 text-center text-sm-center"></app-menu-list>
        <app-menu-list section="footer-2" class="col-4 col-sm-2 text-center text-sm-center"></app-menu-list>
        <app-menu-list section="footer-3" class="col-3"></app-menu-list>

        <app-social-links class="col-12 col-sm-3 d-flex justify-content-center justify-content-sm-end">
        </app-social-links>
    </div>
    <app-lang-opt></app-lang-opt>
    <app-copyright class="container" [isDisabled]="footerLinks"></app-copyright>
</div>