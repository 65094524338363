<div class="container pb-5 pt-0 pt-md-5 content-panel d-flex justify-content-center align-items-center">
    <app-thankyou *ngIf="stream_id==='completed' || stream_status ==='completed'"></app-thankyou>
    <div *ngIf="stream_id!=='completed' && stream_status !=='completed'" class="row w-100">
        <!-- show above tablet -->
        <div class="col-md-4 d-none d-md-block">
            <div class="align-items-center d-flex pt-4">
                <div class="stream-status">
                    <ng-container
                        *ngIf="(stream_status !== 'live' && stream_status !== 'connected' && stream_status !== 'completed'); then loadingContainer; else staticContainer">
                    </ng-container>
                    <ng-template #loadingContainer>
                        <p
                            class="stream-status-icon loadingContainer animation-active active {{stream_status}}-icon text-loading-animation animation-active">
                            {{ 'stream.status.' + stream_status | translate }}</p>
                    </ng-template>
                    <ng-template #staticContainer>
                        <p class="stream-status-icon active {{stream_status}}-icon staticContainer animation-active">
                            {{ 'stream.status.' + stream_status | translate }}</p>
                    </ng-template>
                </div>
                <div class="listeners-count">
                    <img src="{{img_path}}hear_white.svg" alt=""><span [innerHTML]="listeners_count"></span>
                </div>
                <div class="listeners-count">
                    <img src="{{img_path}}clap_fill_White.svg" alt=""><span [innerHTML]="clap_count"></span>
                </div>
            </div>
            <div class="hoster-container">
                <div class="image-description">
                    <p class="profile-title"> {{ (stream_data) ? stream_data?.display_name : 'Unknown' }} <img
                            *ngIf="stream_data && stream_data?.author_details.user_varified !== 'no'"
                            src="{{img_path}}tick_verified.svg" alt=""></p>
                    <span class="followers-count">
                        <img src="{{img_path}}star_fill.svg" alt="">
                        {{ stream_data?.followers }} Followers
                    </span>
                </div>
            </div>
            <app-copy-streamurl [streamurl_data]="get_stream_copy_url()"></app-copy-streamurl>

            <div class="streaming-container mb-4">
                <div class="program-title">
                    <h2 [innerHTML]="stream_data?.stream_title"></h2>
                </div>
                <div class="program-desc">
                    <p *ngIf="readMore" [title]="stream_data?.stream_desc">
                        {{stream_data?.stream_desc | slice:0:250 }}<span
                            *ngIf="stream_data?.stream_desc.length > 250">...</span>
                        <span *ngIf="stream_data?.stream_desc.length > 250" class="read-more-action"
                            (click)="readMore=!readMore">{{'showMore' | translate }}</span>
                    </p>
                    <p *ngIf="!readMore" [title]="stream_data?.stream_desc">
                        {{stream_data?.stream_desc }}
                        <span class="read-more-action" (click)="readMore=!readMore">{{'showLess' | translate
                            }}</span>
                    </p>
                </div>
            </div>
        </div>

        <!-- hide above tablet -->
        <div class="d-md-none col-12">
            <div class="mb-5 row">
                <div class="align-items-center d-flex pb-4 pt-4 col-12 col-sm-6 pl-sm-0 pr-sm-0">
                    <div class="stream-status">
                        <p [ngClass]="{'animation-active': (stream_status === 'waiting' || stream_status === 'connecting')}"
                            class="stream-status-icon active {{stream_status}}-icon text-loading-animation animation-active">
                            {{ 'stream.status.' + stream_status | translate }}</p>
                    </div>
                    <div class="listeners-count">
                        <img src="{{img_path}}hear_white.svg" alt=""><span [innerHTML]="listeners_count"></span>
                    </div>
                    <div class="listeners-count">
                        <img src="{{img_path}}clap_fill_White.svg" alt=""><span [innerHTML]="clap_count"></span>
                    </div>
                </div>
                <div class="col-12 pl-sm-0 pr-sm-0">
                    <app-copy-streamurl [streamurl_data]="get_stream_copy_url()" class="m-auto">
                    </app-copy-streamurl>
                </div>
            </div>
        </div>
        <div class="col-12 col-sm-12 col-md-4 align-self-center">
            <div class="host-express-section">
                <div class="img-responsive {{ stream_status }}" [ngClass]="{active: (is_streaming)}">
                    <div class="img-voice-boarder"></div>
                    <img [src]="stream_data?.avatar_icon" alt="">
                </div>
            </div>
            <div class="timer text-center">
                <div class="count"> {{ elapsed_time }} </div>
            </div>
            <div class="d-md-none text-center">

                <div class="hoster-container">
                    <div class="image-description">
                        <p class="profile-title justify-content-center">{{ stream_data?.display_name }} <img
                                *ngIf="stream_data?.author_details.user_varified !== 'no'"
                                src="{{img_path}}tick_verified.svg" alt="verified"></p>
                        <span class="followers-count">
                            <img src="{{img_path}}star_fill.svg" alt=""> {{ stream_data?.followers }}
                            Followers
                        </span>
                    </div>
                </div>
                <div class="streaming-container mb-4">
                    <div class="program-title">
                        <h2 [innerHTML]="stream_data?.stream_title"></h2>
                    </div>
                    <div class="program-desc">
                        <p *ngIf="readMore" [title]="stream_data?.stream_desc">
                            {{stream_data?.stream_desc | slice:0:100 }}<span
                                *ngIf="stream_data?.stream_desc.length > 100">...</span>
                            <span *ngIf="stream_data?.stream_desc.length > 100" class="read-more-action"
                                (click)="readMore=!readMore">{{'showMore' | translate
                                }}</span>
                        </p>
                        <p *ngIf="!readMore" [title]="stream_data?.stream_desc">
                            {{stream_data?.stream_desc }}
                            <span class="read-more-action" (click)="readMore=!readMore">{{'showLess' | translate
                                }}</span>
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <div class="d-md-block d-none col-md-3">
            <app-chat *ngIf="!is_chat_hide"></app-chat>
        </div>
    </div>
</div>
<div class="footer-container">
    <div class="container" *ngIf="stream_id!=='completed' && stream_status !=='completed'">
        <div *ngIf="!is_loading" class="align-items-center button-group player-control-panel">
            <!-- <div class="align-items-center button-group player-control-panel justify-content-center"> -->
            <ng-template #content let-modal>
                <div class="modal-header">
                    <div class="row">
                        <div class="col-sm-12">
                            <span class="title">Settings</span>
                        </div>
                    </div>
                </div>
                <div class="modal-body settings-panel">
                    <div class="row settings-item">
                        <div class="col-sm-3 mr-2 vl-settings-header">
                            <div class="settings-title" (click)="settings_active_tab = 'audio'"
                                [ngClass]="{'active': (settings_active_tab === 'audio')}">
                                <span class="title">Audio</span>
                            </div>
                            <div class="settings-title" (click)="settings_active_tab = 'status'"
                                [ngClass]="{'active': (settings_active_tab === 'status')}">
                                <span class="title">Debug</span>
                            </div>
                        </div>

                        <div class="col-sm-8 vl-settings-description">
                            <div class="settings-detail" [ngClass]="{'active': (settings_active_tab === 'audio')}">
                                <div ngbDropdown placement="bottom-end" class="d-inline-block col">
                                    <button type="button" class="btn btn-outline-primary" id="dropdownBasic1"
                                        ngbDropdownToggle>{{ selmicDevice }}</button>
                                    <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                                        <button ngbDropdownItem *ngFor="let device of availMicDevices">{{
                                            device.label}}</button>
                                    </div>
                                </div>
                            </div>
                            <div class="settings-detail" [ngClass]="{'active': (settings_active_tab === 'status')}">
                                <p>Connection status: {{ streamcontext_status }}</p>
                            </div>

                        </div>
                    </div>
                </div>
            </ng-template>
            <button (click)="open(content)" [ngbTooltip]="'stream.hostTooltips.settings' | translate"
                tooltipClass="vl-tooltip">
                <img src="{{img_path}}settings_streaming.svg" alt="">
            </button>

            <button [ngClass]="{'recording-muted': is_muted, 'recording': is_streaming || is_muted}" class="main-btn"
                (click)="toggleStream(!is_muted)" (mouseover)="showTooltip(is_streaming, startBtnRef)"
                (mouseleave)="closeTooltip(is_streaming, is_muted, startBtnRef)"
                [ngbTooltip]="stream_action_btn_tooltip_content"
                [tooltipClass]="is_streaming ? 'vl-tooltip': is_muted ? 'vl-tooltip':'stream-start-btn-tooltip vl-tooltip'"
                #startBtnRef="ngbTooltip" triggers="manual" [autoClose]="false">
                <!-- <img src="{{img_path}}streaming_start_white.svg" alt="">  -->
                <svg xmlns="http://www.w3.org/2000/svg" width="107.961" height="107.961" viewBox="0 0 107.961 107.961">
                    <g id="Group_2700" data-name="Group 2700" transform="translate(-847.306 -436.217)">
                        <path class="bg" id="Path_3209" data-name="Path 3209"
                            d="M511.026,2051.5a53.981,53.981,0,1,1-53.98,53.98,53.981,53.981,0,0,1,53.98-53.98"
                            transform="translate(390.26 -1615.278)" />
                        <path class="icon" id="Path_2669" data-name="Path 2669"
                            d="M525.05,2144.043a3.293,3.293,0,0,1-3.288-3.289v-6.845a23,23,0,0,1-19.437-19.027,3.232,3.232,0,0,1,.763-2.593,3.339,3.339,0,0,1,2.532-1.164,3.282,3.282,0,0,1,3.222,2.8,16.453,16.453,0,0,0,32.418,0,3.283,3.283,0,0,1,3.222-2.8,3.356,3.356,0,0,1,2.568,1.193,3.156,3.156,0,0,1,.719,2.558,23,23,0,0,1-19.434,19.025v6.845a3.293,3.293,0,0,1-3.286,3.3m0-23.039a9.879,9.879,0,0,1-9.864-9.873v-19.748a9.865,9.865,0,1,1,19.73,0v19.748A9.881,9.881,0,0,1,525.05,2121m0-32.912a3.293,3.293,0,0,0-3.288,3.292v19.748a3.289,3.289,0,1,0,6.576.141c0-.046,0-.093,0-.141v-19.748a3.293,3.293,0,0,0-3.287-3.29Z"
                            transform="translate(375.327 -1622.538)" stroke="#e71c4c" stroke-width="2" />
                    </g>
                </svg>
                <div class="animation"></div>
            </button>

            <button (click)="stopStream()" [ngbTooltip]="'stream.hostTooltips.stop' | translate"
                tooltipClass="vl-tooltip">
                <img src="{{img_path}}streaming_stop.svg" alt="">
            </button>
        </div>
        <div *ngIf="is_loading" class="align-items-center justify-content-center text-center loader">
            <div class="spinner1 justify-content-center">
                <div *ngFor="let dot of counter(5)" class="dots"></div>
            </div>
        </div>
    </div>

</div>

<!-- mobile chat popup -->
<div class="d-md-none" *ngIf="stream_id!=='completed' && stream_status !=='completed'">
    <div class="chat-icon-popup cursor-pointer" [ngClass]="{'active': !chat_popup}" (click)="chat_popup=true">
        <img src="{{img_path}}chat.svg" alt="">
    </div>
    <div class="chat-popup-window" [ngClass]="{'active': chat_popup}">
        <button type="button" (click)="chat_popup=false" class="close" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
        <app-chat *ngIf="!is_chat_hide"></app-chat>
    </div>
</div>

<div class="main-loader" *ngIf="is_loading && stream_id!=='completed' && stream_status !=='completed'">
    <app-waiting-slider></app-waiting-slider>
    <p class="stream-status-icon active text-loading-animation animation-active">
        Please wait</p>
    <!-- <span class="count-loading-animation counter">{{stream_counter}}</span> -->
    <span class="counter">{{stream_counter}}</span>
</div>
