import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-streaming-card',
  templateUrl: './streaming-card.component.html',
  styleUrls: ['./streaming-card.component.scss']
})
export class StreamingCardComponent implements OnInit {
  @Input() stream_id = this.activatedRoute.snapshot.paramMap.get('stream-id');
  @Input() is_popup = false;

  route = this.router.url;

  constructor(private activatedRoute: ActivatedRoute, private router: Router, private modal: NgbModal) { }
  close() {
    this.modal.dismissAll();
  }
  ngOnInit(): void { }

}
