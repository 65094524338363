<div *ngIf="status === 'error'" class="empty-text h-100 unselectable">
    <p [innerHTML]="error">No streams scheduled</p>
</div>
<div class="col-sm-12">

    <div class="card-deck thin-scrollbar responsive" *ngIf="list?.length > 0">
        <div *ngFor="let item of list" class="mb-5 p-0 scheduled-card">
            <div class="card">

                <div class="card-inner position-relative">
                    <div class="card-header">
                        <span>{{ item.streamtime | date: "dd-MM-y | h:mm a" }}</span>
                        <button (click)="removeStreaming($event, item.post_id)" class="delete-opt btn">
                            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                                viewBox="0,0,256,256" width="32px" height="32px" fill-rule="nonzero">
                                <g fill-rule="nonzero" stroke="none" stroke-width="1" stroke-linecap="butt"
                                    stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray=""
                                    stroke-dashoffset="0" font-family="none" font-weight="none" font-size="none"
                                    text-anchor="none" style="mix-blend-mode: normal">
                                    <g transform="scale(8,8)">
                                        <path
                                            d="M15,4c-0.52344,0 -1.05859,0.18359 -1.4375,0.5625c-0.37891,0.37891 -0.5625,0.91406 -0.5625,1.4375v1h-6v2h1v16c0,1.64453 1.35547,3 3,3h12c1.64453,0 3,-1.35547 3,-3v-16h1v-2h-6v-1c0,-0.52344 -0.18359,-1.05859 -0.5625,-1.4375c-0.37891,-0.37891 -0.91406,-0.5625 -1.4375,-0.5625zM15,6h4v1h-4zM10,9h14v16c0,0.55469 -0.44531,1 -1,1h-12c-0.55469,0 -1,-0.44531 -1,-1zM12,12v11h2v-11zM16,12v11h2v-11zM20,12v11h2v-11z">
                                        </path>
                                    </g>
                                </g>
                            </svg>
                        </button>
                    </div>
                    <img (click)="open(item.stream_id)" class="cursor-pointer card-img-top"
                        src="{{img_path}}audio_wave_white.svg" alt="Card image cap">
                    <!-- <div class="more-opt">
                    <button (click)="open(item.stream_id)" class="delete-opt btn">
                        <svg xmlns="http://www.w3.org/2000/svg" width="90.667" height="90.667" version="1.0"
                            viewBox="0 0 68 68">
                            <path
                                d="M29.5 2.7c-1.1.3-3.4 1.5-5 2.8l-3.1 2.3-2.7-2.4C11.6-.7 1.5 6.6 5.1 15.2c1.5 3.7 6.1 6.1 10 5.3 3.9-.9 3.9 1.3-.1 5.7-2.2 2.3-3 4.2-3 6.8.1 8.5 5.4 16.7 13 20.2 3.2 1.5 5 2.9 5 4 0 1-.7 1.8-1.5 1.8-.9 0-2.1 1.1-2.7 2.5-.6 1.4-1.9 2.5-3 2.5-1 0-1.8.4-1.8 1s4.8 1 12.5 1S46 65.6 46 65s-.8-1-1.9-1c-1 0-2.1-.9-2.4-2.1-.3-1.1-1.5-2.3-2.6-2.6-3.9-1-2.4-3.8 3.2-6.1 6.7-2.8 11.4-2.8 14.3-.1l2.2 2.1 2.6-3.8c2-2.9 2.6-5.1 2.6-9.1 0-7.4-.6-8.3-5.1-8.3-3.4 0-3.9-.3-3.9-2.3 0-1.3-1.3-3.6-2.9-5.3-2.3-2.3-2.9-4-3.3-8.7-.5-6-2.4-9.5-6.9-13-2.7-1.9-8.9-3-12.4-2zm10.6 2.8c4.4 2.3 6.9 7.1 6.9 13.2V24H20v-2.5c0-1.4.6-2.5 1.3-2.5.8 0 1.2-1.7 1.2-5.2 0-4.8.3-5.4 3.4-7.5 4-2.7 9.8-3 14.2-.8zm-25.6 4c1.9 1.5 1.9 1.5-.2 3.6-2.4 2.5-4.3 1.8-4.3-1.5 0-3.8 1.3-4.4 4.5-2.1zm38.5 22c0 1.8-.5 2.5-2 2.5-2.5 0-2.6-1.8-.2-3.6 2.3-1.8 2.2-1.8 2.2 1.1zm-35 2.9c0 3.2 3.8 9.9 6.8 12.1 2.8 2.1 11.6 2.8 15.8 1.2 1.7-.6 2.3-.3 2.7 1.2 1 4.1-11.6 6.1-17.9 2.8C19 48.3 14 40.6 14 34c0-3.8.1-3.9 2-2.8 1.1.7 2 2.2 2 3.2zm43.8 7.5c.2 5 0 6.7-1.2 7.3-.9.5-1.6 1.6-1.6 2.5 0 1.4-.4 1.3-2.3-.5-1.7-1.6-3.6-2.2-6.9-2.2-5.1 0-5.8-.8-5.8-7 0-6.9.3-7.1 9.4-6.8l8.1.3.3 6.4z" />
                            <path
                                d="M47.5 41.9c-.6 1.1.9 2.3 1.9 1.4.9-.9.7-2.3-.3-2.3-.6 0-1.2.4-1.6.9zM52 41.9c0 .6.5 1.3 1 1.6.6.3 1-.1 1-.9 0-.9-.4-1.6-1-1.6-.5 0-1 .4-1 .9zM57 42.6c0 .8.5 1.2 1 .9.6-.3 1-1 1-1.6 0-.5-.4-.9-1-.9-.5 0-1 .7-1 1.6z" />
                        </svg>
                    </button>
                    <button (click)="removeStreaming($event, item.post_id)" class="delete-opt btn">
                        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                            viewBox="0,0,256,256" width="32px" height="32px" fill-rule="nonzero">
                            <g fill-rule="nonzero" stroke="none" stroke-width="1" stroke-linecap="butt"
                                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0"
                                font-family="none" font-weight="none" font-size="none" text-anchor="none"
                                style="mix-blend-mode: normal">
                                <g transform="scale(8,8)">
                                    <path
                                        d="M15,4c-0.52344,0 -1.05859,0.18359 -1.4375,0.5625c-0.37891,0.37891 -0.5625,0.91406 -0.5625,1.4375v1h-6v2h1v16c0,1.64453 1.35547,3 3,3h12c1.64453,0 3,-1.35547 3,-3v-16h1v-2h-6v-1c0,-0.52344 -0.18359,-1.05859 -0.5625,-1.4375c-0.37891,-0.37891 -0.91406,-0.5625 -1.4375,-0.5625zM15,6h4v1h-4zM10,9h14v16c0,0.55469 -0.44531,1 -1,1h-12c-0.55469,0 -1,-0.44531 -1,-1zM12,12v11h2v-11zM16,12v11h2v-11zM20,12v11h2v-11z">
                                    </path>
                                </g>
                            </g>
                        </svg>
                    </button>
                </div> -->
                </div>
                <div class="position-relative">
                    <h5 (click)="open(item.stream_id)" [ngbTooltip]="item.title" tooltipClass="card-tooltip"
                        class="cursor-pointer card-title text-inline-single-line">{{
                        item.title }}</h5>
                    <p class="card-text">Code {{ item.passcode }}</p>
                </div>
            </div>
        </div>
    </div>
</div>