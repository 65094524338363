import { Component, OnInit } from '@angular/core';
import { NgbCarouselModule } from '@ng-bootstrap/ng-bootstrap';
import { NgIf } from '@angular/common';

@Component({
  selector: 'app-waiting-slider',
  templateUrl: './waiting-slider.component.html',
  styleUrls: ['./waiting-slider.component.scss']
})
export class WaitingSliderComponent implements OnInit {

  images = [944, 1011, 984].map((n) => `https://picsum.photos/id/${n}/900/500`);
cards = [
{
  title: "Avoid Screen Timeouts",
  description: "Adjust your device settings to prevent automatic screen timeouts. This helps prevent any interruptions during your live audio streaming. Keeping your screen active ensures continuous streaming."
},
{
  title: "Stay on the App",
  description: "Keep the streaming app as the active foreground app on your phone. Switching to other apps or navigating away from the streaming app may pause or disconnect the stream. Please refrain from app changes during your live session for a seamless streaming experience."
}
];
  constructor() { }

  ngOnInit(): void {
  }

}
