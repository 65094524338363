import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { DataStoreService } from 'src/app/apis/data-storage/data-store.service';
import { SignupService } from 'src/app/apis/signup/signup.service';
import { ToastService } from 'src/app/apis/toast.service';


@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {

  @Input() user_details: any;

  profile_details = new FormGroup({
    display_name: new FormControl('', Validators.required),
    user_bio: new FormControl(''),
    password_reset: new FormControl('no'),
    user_profile_visibility: new FormControl(false),
  });
  avatar = new FormControl();
  is_submitted: boolean = false;
  make_private_tooltip = "make my profile invisible from public";
  user_token: string = '';
  toast_opt = { classname: 'toast-success', header: ' ' }
  profile_update = false;
  user_img: any;
  img_path = '';
  constructor(
    private data_store_api: DataStoreService,
    private user_api: SignupService,
    private toast: ToastService
  ) { }

  showMe(event: any) {
    
    const file = event.target.files[0];
    // const reader = new FileReader();
    // reader.readAsDataURL(file);
    // reader.onloadend = ($event: any) => {
    //   this.avatar.patchValue($event.target.result);
    //   this.user_details.user_avatar = $event.target.result;
    //   this.profile_update = true;
    // };
    this.updateAvatar(file);
  }

  updateAvatar(avatar: any) {
    let formData = new FormData();
    formData.append('user_avatar', avatar);

    this.user_api.updateAvatar(formData, this.user_token).subscribe((result: any) => {
      if (result.status === "success") {
        this.user_details.user_avatar = result.image_url;
        localStorage.setItem('user_details', JSON.stringify(this.user_details));
        this.data_store_api.updateUserDetails(JSON.stringify(this.user_details));
        this.toast_opt.header = "Profile updated!";
      } else {
        this.toast_opt.header = "Failed to profile update";
        this.toast_opt.classname = "toast-error";
      }
      this.toast.show(result.message, this.toast_opt);
    }, (error: any) => {
      this.toast_opt.header = "Failed to profile update";
      this.toast_opt.classname = "toast-error";
      this.toast.show(error?.message, this.toast_opt);
    });


  }

  updateProfile(values: FormGroup) {
    if (this.user_token !== '') {
      this.is_submitted = true;
      values.value.user_profile_visibility = (values.value.user_profile_visibility) ? 'private' : 'public';
      
      this.user_api.updateUser(values.value, this.user_token).subscribe((result: any) => {
        localStorage.setItem('user_details', JSON.stringify(result.updated_user_data));
        this.data_store_api.updateUserDetails(JSON.stringify(result.updated_user_data));
        this.toast_opt.header = result?.status;
        this.toast.show(result?.message, this.toast_opt);
      }, ((error: any) => {
        this.toast_opt.classname = 'toast-error';
        this.toast_opt.header = error?.status;
        this.toast.show(error?.message, this.toast_opt);
      }))
    }

  }

  ngOnInit() {
    this.data_store_api.currentBaseUrl.subscribe((url: string) => this.img_path = url + "assets/img/icons/");
    if (this.user_details?.user_total_streams) {
      this.user_details.user_total_streams = String(this.user_details?.user_total_streams).padStart(2, '0');
    }
    if (this.user_details?.user_total_following) {
      this.user_details.user_total_following = String(this.user_details?.user_total_following).padStart(2, '0');
    }
    if (this.user_details.user_total_followers) {
      this.user_details.user_total_followers = String(this.user_details?.user_total_followers).padStart(2, '0');
    }
    this.data_store_api.currentUserToken.subscribe((token: any) => this.user_token = token);
    this.profile_details.patchValue({
      display_name: this.user_details?.display_name,
      user_bio: this.user_details?.user_bio,
      user_profile_visibility: this.user_details?.user_profile_visibility === 'yes'
    });
    this.avatar.setValue(this.user_details.user_avatar);
  }

}
