import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule, HttpClient } from "@angular/common/http";
import { AppRoutingModule } from './app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ReactiveFormsModule } from '@angular/forms';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { ModalModule } from 'ngx-bootstrap/modal';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';
import { ClipboardModule } from '@angular/cdk/clipboard'
import { AngularFireModule } from '@angular/fire';
import { AngularFireDatabaseModule } from '@angular/fire/database';


import { AppComponent } from './app.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { HomeComponent } from './home/home.component';
import { LogoComponent } from './logo/logo.component';
import { MainmenuComponent } from './header/mainmenu/mainmenu.component';
import { SettingsmenuComponent } from './header/settingsmenu/settingsmenu.component';
import { SocialLinksComponent } from './social-links/social-links.component';
import { FooterlinksComponent } from './footer/footerlinks/footerlinks.component';
import { LangOptComponent } from './footer/lang-opt/lang-opt.component';
import { ScrollTopComponent } from './scroll-top/scroll-top.component';
import { BannerComponent } from './home/banner/banner.component';
import { Section1Component } from './home/section1/section1.component';
import { Section2Component } from './home/section2/section2.component';
import { Section3Component } from './home/section3/section3.component';
import { AppDownloadComponent } from './home/app-download/app-download.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { StreamComponent } from './stream/stream.component';
import { HostingComponent } from './stream/hosting/hosting.component';
import { ListeningComponent } from './stream/listening/listening.component';
import { ChatComponent } from './stream/chat/chat.component';
import { ControlPanelComponent } from './stream/control-panel/control-panel.component';
import { TabsComponent } from './dashboard/tabs/tabs.component';
import { SettingsComponent } from './dashboard/tabs/settings/settings.component';
import { ProfileComponent } from './dashboard/tabs/profile/profile.component';
import { ScheduledComponent } from './dashboard/tabs/streams/scheduled/scheduled.component';
import { CompletedComponent } from './dashboard/tabs/streams/completed/completed.component';
import { LoginComponent } from './signup/login/login.component';
import { RegisterComponent } from './signup/register/register.component';
import { SignupComponent } from './signup/signup.component';
import { NewStreamingComponent } from './stream/hosting/new-streaming/new-streaming.component';
import { StreamingFormComponent } from './stream/hosting/new-streaming/streaming-form/streaming-form.component';
import { StreamingDetailComponent } from './stream/hosting/new-streaming/streaming-detail/streaming-detail.component';
import { StreamingCardComponent } from './stream/hosting/new-streaming/streaming-card/streaming-card.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { MenuListComponent } from "./footer/menu-list/menu-list.component";

import { AuthService } from './apis/auth/auth.service';
import { StreamService } from './apis/stream.service';
import { SingleComponent } from './single/single.component';

import { environment } from 'src/environments/environment';
import { PickerModule } from '@ctrl/ngx-emoji-mart';
import { PrivateStreamingUnknownUserComponent } from './popups/private-streaming-unknown-user/private-streaming-unknown-user.component';
import { ConfirmWindowComponent } from './popups/confirm-window/confirm-window.component';
import { ToastComponent } from './templates/toast/toast.component';
import { CopyStreamurlComponent } from './stream/hosting/copy-streamurl/copy-streamurl.component';
import { NotificationsComponent } from './header/notifications/notifications.component';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { CopyrightComponent } from './footer/copyright/copyright.component';
import { ThankyouComponent } from './stream/hosting/thankyou/thankyou.component';

import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { ServiceWorkerModule } from '@angular/service-worker';
import { TourComponent } from './popups/tour/tour.component';
import { PrivateListeningTokenComponent } from './popups/private-listening-token/private-listening-token.component';
import { WaitingSliderComponent } from './stream/hosting/waiting-slider/waiting-slider.component';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    HomeComponent,
    LogoComponent,
    MainmenuComponent,
    SettingsmenuComponent,
    SocialLinksComponent,
    FooterlinksComponent,
    LangOptComponent,
    ScrollTopComponent,
    BannerComponent,
    Section1Component,
    Section2Component,
    Section3Component,
    AppDownloadComponent,
    DashboardComponent,
    StreamComponent,
    HostingComponent,
    ListeningComponent,
    ChatComponent,
    ControlPanelComponent,
    TabsComponent,
    SettingsComponent,
    ProfileComponent,
    ScheduledComponent,
    CompletedComponent,
    LoginComponent,
    RegisterComponent,
    SignupComponent,
    NewStreamingComponent,
    StreamingFormComponent,
    StreamingDetailComponent,
    StreamingCardComponent,
    PageNotFoundComponent,
    MenuListComponent,
    SingleComponent,
    PrivateStreamingUnknownUserComponent,
    ConfirmWindowComponent,
    ToastComponent,
    CopyStreamurlComponent,
    NotificationsComponent,
    CopyrightComponent,
    ThankyouComponent,
    TourComponent,
    PrivateListeningTokenComponent,
    WaitingSliderComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgbModule,
    TabsModule.forRoot(),
    NgbDropdownModule,
    ClipboardModule,
    HttpClientModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    AngularFireDatabaseModule,
    AngularFireModule.initializeApp(environment.firebase),
    PickerModule,
    ModalModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpClient]
      }
    }),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the app is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    })
  ],
  providers: [
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    AuthService,
    StreamService,
    PrivateStreamingUnknownUserComponent,
    ConfirmWindowComponent,
    {provide: 'googleTagManagerId', useValue: 'GTM-54DL5KS'}
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
