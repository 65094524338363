<div *ngIf="has_list" class="container pb-sm-5 pt-sm-5">
    <h3 class="text-center pt-sm-5">What's trending</h3>
    <div class="grid-container pb-5">
        <div class="card-container">

            <div [class.has-streams]="has_list" class="row stream-list row-cols-2 row-cols-sm-3 row-cols-md-4 row-cols-lg-6">
                <div class="col mb-4" *ngFor="let stream of stream_list">
                    <a [routerLink]="stream?.listen_url">
                        <div class="card h-100">
                            <img [src]="stream?.user_avatar" class="card-img-top rounded" [alt]="stream?.user_avatar">
                            <div class="card-body">
                                <h5 [title]="stream?.display_name" class="card-title" [innerHTML]="stream?.display_name"></h5>
                                <p class="card-text" [innerHTML]="stream.title"></p>
                            </div>
                        </div>
                    </a>
                </div>
            </div>

        </div>
    </div>
    <div class="show-more-btn-container col-sm-4 container d-flex justify-content-center">
        <button class="btn-showmore">Show more</button>
    </div>
</div>