import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-social-links',
  templateUrl: './social-links.component.html',
  styleUrls: ['./social-links.component.scss']
})
export class SocialLinksComponent implements OnInit {

  social_links = [
    { name: 'facebook', img: './assets/img/social-icons/facebook.png', link: 'https://www.facebook.com/vocoloop'},
    { name: 'instagram', img: './assets/img/social-icons/instagram.png', link: 'https://www.instagram.com/vocoloop'},
    { name: 'twitter', img: './assets/img/social-icons/twitter.png', link: 'https://www.twitter.com/vocoloop'},
  ]
  constructor() { }

  ngOnInit(): void {
  }

}
