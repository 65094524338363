<div class="container">
    <div class="align-items-center justify-content-around pb-2 pt-2 pb-sm-4 pt-sm-4 row">
        <div class="col-8 row align-items-center">
            <app-logo class="p-sm-0 pl-3 pr-3"></app-logo>
            <!-- <app-mainmenu menuLayout="inline"></app-mainmenu> -->
        </div>
        <app-settingsmenu class="col-4"></app-settingsmenu>
    </div>
</div>
<div class="position-relative">
    <app-toast></app-toast>
</div>