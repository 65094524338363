<!-- If user not logged in -->
<div *ngIf="!logged_in">

    <!-- If greater than mobile screen -->
    <div class="d-none d-sm-block">
        <ul class="list list-inline d-flex justify-content-end mb-0">
            <li class="list-inline-item login-btn-outer">
                <a routerLink="/signup/login" class="login-btn">Login</a>
            </li>
            <li class="list-inline-item signup-btn-outer">
                <a routerLink="/signup/register" class="signup-btn">Signup</a>
            </li>
        </ul>
    </div>

    <!-- If mobile screen -->
    <div class="d-sm-none">

        <ul class="list list-inline d-flex justify-content-end mb-0">
            <li class="list-inline-item login-btn-outer">

                <a (click)="popup=true"><img src="{{img_path}}/form/username.svg" alt=""></a>
            </li>
        </ul>
        <div class="popup-window" [ngClass]="{'show': popup}">
            <div class="popup-panel">
                <button type="button" (click)="popup=false" class="close" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
                <div class="popup-top">
                    <a routerLink="/signup/login" (click)="popup=false" class="login-btn btn">Login</a>
                </div>
                <div class="popup-bottom">
                    <a routerLink="/signup/register" (click)="popup=false" class="signup-btn btn">Signup</a>
                </div>
            </div>
        </div>
    </div>

</div>

<!-- If user logged in -->
<div *ngIf="logged_in" class="loggedin">
    <!-- web menu -->
    <div class="d-none d-sm-block">
        <ul class="list list-inline d-flex justify-content-end align-items-center mb-0">

            <li *ngIf="streaming_btn_status" class="list-inline-item">
                <a [routerLink]="streaming_btn.link" class="no-border bg-default btn btn-sm rounded p-0 pl-2 pr-2"
                    [innerHTML]="is_streaming_ongoing ? ('backToLiveBtn' | translate) : ('goLiveBtn' | translate)"></a>
            </li>
            <li class="list-inline-item">
                <app-notifications class="d-block"></app-notifications>
            </li>
            <div ngbDropdown class="sg-dropdown">
                <button ngbDropdownToggle><img [src]="user_details.user_avatar" class="rounded-pill" alt="">
                    {{ user_details.display_name }}
                </button>
                <div ngbDropdownMenu aria-labelledby="dropdown-menu dropdown-menu-right">
                    <a ngbDropdownItem routerLink="/dashboard">{{'dashboard.title' | translate}}</a>
                    <a ngbDropdownItem (click)="userLogout()">{{'logout' | translate}}</a>
                </div>
            </div>
        </ul>
    </div>
    <!-- web menu ends here -->

    <!-- mobile menu end here-->
    <div class="d-sm-none">
        <ul class="list list-inline d-flex justify-content-end mb-0">
            <li class="list-inline-item login-btn-outer">
                <a (click)="popup=true">
                    <img [src]="user_details.user_avatar" alt="">
                    <span *ngIf="is_streaming_ongoing && streaming_btn_status" class="recorder-icon">live <i></i></span>
                </a>
            </li>
        </ul>
        <div class="popup-window" [ngClass]="{'show': popup}">
            <div class="popup-panel">
                <button type="button" (click)="popup=false" class="close" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
                <div class="menu-list">
                    <div class="list-items text-center">
                        <a *ngIf="streaming_btn_status" [routerLink]="streaming_btn.link"
                            class="no-border btn btn-sm theme-btn-bordered"
                            [innerHTML]="is_streaming_ongoing ? ('backToLiveBtn' | translate) : ('goLiveBtn' | translate)"></a>

                        <a class="link" (click)="popup=false" routerLink="/dashboard" fragment="profile">Dashboard</a>
                        <a class="link" (click)="userLogout()">Logout</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- mobile menu end here-->

</div>