import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import { FormControl } from '@angular/forms';
import { NgbTooltip, NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import { DataStoreService } from 'src/app/apis/data-storage/data-store.service';

@Component({
  selector: 'app-copy-streamurl',
  templateUrl: './copy-streamurl.component.html',
  styleUrls: ['./copy-streamurl.component.scss']
})
export class CopyStreamurlComponent implements OnInit {
  @ViewChild('copyLink') copyLink: ElementRef | undefined;
  @Input() streamurl_data: any = {};

  copy_url = new FormControl();
  copy_url_tooltip = "Click to copy url";
  copied_url_tooltip = "Stream url copied";
  is_copied = false;
  img_path = '';
  constructor(private data_store_api: DataStoreService) { }
  linkCopied(tooltip: NgbTooltip, popover: NgbPopover) {
    this.leaveTooltip(popover);
    this.showTooltip(tooltip);
    setTimeout(() => {
      this.leaveTooltip(tooltip);
    }, 5000);
  }
  CopyLink(tooltip: NgbTooltip, popover: NgbPopover) {
    this.leaveTooltip(tooltip);
    this.showTooltip(popover);
  }

  showTooltip(tooltip: any) {
    tooltip.open();
  }

  leaveTooltip(tooltip: any) {
    tooltip.close();
  }

  ngOnInit(): void {
    this.data_store_api.currentBaseUrl.subscribe((url: string) => this.img_path = url + "assets/img/icons/");
    setTimeout(() => {
      this.copy_url.setValue(this.copyLink?.nativeElement.href + this.streamurl_data?.url);
    }, 500);
  }
}
